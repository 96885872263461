import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router"; // 1、引入配置好的router
import request from "@/utils/request";
import { filterForm } from "@/utils/ruoyi";
import "./assets/css/myflex.css";
import "./assets/css/lindy-uikit.css";

import DemoBlock from "@/components/md_docs/DemoBlock.vue";
Vue.component("demo-block", DemoBlock);

// 引入ElementUI组件库
import ElementUI from "element-ui";
//引入ElementUI的所有样式
import "element-ui/lib/theme-chalk/index.css";

//关闭Vue的生产提示
//使用ElementUI
Vue.use(ElementUI);
Vue.mixin({
  computed: {
    IsMmobile() {
      return store.state.device === 'mobile'
    },
    ProviderUser: {
      get() {
        return store.getters.providerUser;
      },
      set(val) {
        store.state.providerUser = val;
      },
    },
  },
});
//空值过滤器
Vue.prototype.filterForm = filterForm
Vue.config.productionTip = false;

Vue.prototype.$http = request;

import * as filters from './filters' // global filters 
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  router, //2、申明router
  store,
  render: (h) => h(App),
}).$mount("#app");
