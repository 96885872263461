var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content element-doc"},[_vm._m(0),_vm._m(1),_c('demo-block',[_c('template',{slot:"source"},[_c('element-demo0',{staticClass:"element-demo0"})],1)],2),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"shang-hu-ding-dan-lie-biao-cha-xun","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#shang-hu-ding-dan-lie-biao-cha-xun"}},[_vm._v("¶")]),_vm._v(" 商户订单列表查询")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("accessToken接口说明：\n1：用来获取订单列表，根据商户授权的accessToken查对应的商户订单列表。\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"qing-qiu-url","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#qing-qiu-url"}},[_vm._v("¶")]),_vm._v(" 请求url")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('code',[_vm._v("POST")]),_vm._v(" /open/api/paoOrder/orderPage")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"qian-ming-ji-suan","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#qian-ming-ji-suan"}},[_vm._v("¶")]),_vm._v(" 签名计算")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"/docs/developers_guide"}},[_vm._v("请参考开发指南")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ce-shi-lian-diao","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ce-shi-lian-diao"}},[_vm._v("¶")]),_vm._v(" 测试联调")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"/docs/testing_and_debugging"}},[_vm._v("联调说明")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ru-can-shuo-ming","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ru-can-shuo-ming"}},[_vm._v("¶")]),_vm._v(" 入参说明")])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("参数")]),_c('th',[_vm._v("示例")]),_c('th',[_vm._v("说明")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("clientId")]),_c('td',[_vm._v("ss6DzHy9GvHB46Jgo")]),_c('td',[_vm._v("App-key")])]),_c('tr',[_c('td',[_vm._v("accessToken")]),_c('td',[_vm._v("70882965-5e51-463f-a746-7bcf1b3f1c34")]),_c('td',[_c('a',{attrs:{"href":"/docs/merchant_authorization"}},[_vm._v("通过商户授权获取")])])]),_c('tr',[_c('td',[_vm._v("timestamp")]),_c('td',[_vm._v("1631862240982")]),_c('td',[_vm._v("毫秒级时间戳")])]),_c('tr',[_c('td',[_vm._v("data")]),_c('td',[_vm._v("参考入参示例")]),_c('td',[_vm._v("业务入参")])]),_c('tr',[_c('td',[_vm._v("sign")]),_c('td',[_vm._v("63068EBC3C28B7F822F74DAB874D4027")]),_c('td',[_vm._v("签名计算参考"),_c('a',{attrs:{"href":"/docs/developers_guide"}},[_vm._v("开发指南")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"datacan-shu-shuo-ming","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#datacan-shu-shuo-ming"}},[_vm._v("¶")]),_vm._v(" data参数说明")])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("参数")]),_c('th',[_vm._v("字段描述")]),_c('th',[_vm._v("类型/是否必传")]),_c('th',[_vm._v("说明")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("current")]),_c('td',[_vm._v("页面")]),_c('td',[_vm._v("Integer(8)/NO")]),_c('td',[_vm._v("查询第几页，默认:1")])]),_c('tr',[_c('td',[_vm._v("size")]),_c('td',[_vm._v("每页数据量")]),_c('td',[_vm._v("Integer(8)/NO")]),_c('td',[_vm._v("默认:10")])]),_c('tr',[_c('td',[_vm._v("ascs")]),_c('td',[_vm._v("每页数据量")]),_c('td',[_vm._v("String(8)/NO")]),_c('td',[_vm._v("升序字段名 ascs与descs只需要写1个，")])]),_c('tr',[_c('td',[_vm._v("descs")]),_c('td',[_vm._v("每页数据量")]),_c('td',[_vm._v("String(8)/NO")]),_c('td',[_vm._v("降序字段名 ascs与descs只需要写1个")])]),_c('tr',[_c('td',[_vm._v("orderStatus")]),_c('td',[_vm._v("状态")]),_c('td',[_vm._v("String(8)/NO")]),_c('td',[_vm._v("默认：0 0：全部 1：进行中 2：完成")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"chu-can-shuo-ming","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#chu-can-shuo-ming"}},[_vm._v("¶")]),_vm._v(" 出参说明")])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("参数")]),_c('th',[_vm._v("类型/是否必传")]),_c('th',[_vm._v("说明")]),_c('th',[_vm._v("示例")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("records")]),_c('td',[_vm._v("Objext[]/YES")]),_c('td',[_vm._v("订单列表")]),_c('td')]),_c('tr',[_c('td',[_vm._v("total")]),_c('td',[_vm._v("Integer(2)/YES")]),_c('td',[_vm._v("总数")]),_c('td')]),_c('tr',[_c('td',[_vm._v("current")]),_c('td',[_vm._v("页面")]),_c('td',[_vm._v("Integer(8)/NO")]),_c('td',[_vm._v("查询第几页，默认:1")])]),_c('tr',[_c('td',[_vm._v("size")]),_c('td',[_vm._v("每页数据量")]),_c('td',[_vm._v("Integer(8)/NO")]),_c('td',[_vm._v("默认:10")])]),_c('tr',[_c('td',[_vm._v("orders")]),_c('td',[_vm._v("Integer(2)/YES")]),_c('td',[_vm._v("排序方式")]),_c('td')]),_c('tr',[_c('td',[_vm._v("pages")]),_c('td',[_vm._v("Integer(2)/YES")]),_c('td',[_vm._v("总页数")]),_c('td')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ru-can-shi-li-datawei-ye-wu-can-shu-jsonxu-lie-hua-hou-chuan-ru","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ru-can-shi-li-datawei-ye-wu-can-shu-jsonxu-lie-hua-hou-chuan-ru"}},[_vm._v("¶")]),_vm._v(" 入参示例(data为业务参数，Json序列化后传入 )")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("{\n    \"clientId\": \"t8ljLE2mKPwEgAcVr\",\n    \"accessToken\": \"d077bb79-4c8b-46a8-a06e-4a01a98bff6a\",\n    \"timestamp\": \"1631862240982\",\n    \"sign\": \"FB993D2759E82E7050111BF30E988667\",\n    \"data\": \"{\\\"current\\\": 2}\"\n}\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ye-wu-can-shu-shi-li","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ye-wu-can-shu-shi-li"}},[_vm._v("¶")]),_vm._v(" 业务参数示例")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("{\n      current: 1,\n      size: 10,\n      descs: 'create_time'\n}\n\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"chu-can-shi-li","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#chu-can-shi-li"}},[_vm._v("¶")]),_vm._v(" 出参示例")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("{\n    \"msg\": \"操作成功\",\n    \"code\": 200,\n    \"data\": {\n        \"records\": [],\n        \"total\": 58,\n        \"size\": 10,\n        \"current\": 2,\n        \"orders\": [],\n        \"optimizeCountSql\": true,\n        \"hitCount\": false,\n        \"searchCount\": true,\n        \"pages\": 6\n    },\n    \"status\": \"SUCCESS\"\n}\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"jie-kou-zhu-yi-dian","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#jie-kou-zhu-yi-dian"}},[_vm._v("¶")]),_vm._v(" 接口注意点")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("\n\n")])])
}]

export { render, staticRenderFns }