
    <template>
      <section class="content element-doc">
        <h3 id="ding-dan-qu-xiao" tabindex="-1"><a class="header-anchor" href="#ding-dan-qu-xiao">¶</a> 订单取消</h3>
<pre><code AAA="">接口说明：
接口说明：订单在骑手取件后用户申请取消，调用了订单取消接口后，订单的状态是40（闪送中），在骑手把物品送回后，调用该接口，订单状态流转到60（已取消）。

</code></pre>
<demo-block>
        
        <template slot="source"><element-demo0 class="element-demo0"/></template>
        </demo-block><h4 id="qing-qiu-url" tabindex="-1"><a class="header-anchor" href="#qing-qiu-url">¶</a> 请求url</h4>
<p><code>POST</code>  /open/api/paoOrder/confirmGoodsReturn</p>
<h4 id="qian-ming-ji-suan" tabindex="-1"><a class="header-anchor" href="#qian-ming-ji-suan">¶</a> 签名计算</h4>
<p><a href="/docs/developers_guide">请参考开发指南</a></p>
<h4 id="ce-shi-lian-diao" tabindex="-1"><a class="header-anchor" href="#ce-shi-lian-diao">¶</a> 测试联调</h4>
<p><a href="/docs/testing_and_debugging">联调说明</a></p>
<h4 id="ru-can-shuo-ming" tabindex="-1"><a class="header-anchor" href="#ru-can-shuo-ming">¶</a> 入参说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>示例</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>clientId</td>
<td>ss6DzHy9GvHB46Jgo</td>
<td>App-key</td>
</tr>
<tr>
<td>accessToken</td>
<td>70882965-5e51-463f-a746-7bcf1b3f1c34</td>
<td><a href="/docs/merchant_authorization">通过商户授权获取</a></td>
</tr>
<tr>
<td>timestamp</td>
<td>1631862240982</td>
<td>毫秒级时间戳</td>
</tr>
<tr>
<td>data</td>
<td>参考入参示例</td>
<td>业务入参</td>
</tr>
<tr>
<td>sign</td>
<td>63068EBC3C28B7F822F74DAB874D4027</td>
<td>签名计算参考<a href="/docs/developers_guide">开发指南</a></td>
</tr>
</tbody>
</table>
<h4 id="datacan-shu-shuo-ming" tabindex="-1"><a class="header-anchor" href="#datacan-shu-shuo-ming">¶</a> data参数说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>字段描述</th>
<th>类型/是否必传</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>orderId</td>
<td>订单ID号</td>
<td>String(32)/YES</td>
<td><a href="/docs/orderPlace">订单提交接口获取</a></td>
</tr>
</tbody>
</table>
<h4 id="chu-can-shuo-ming" tabindex="-1"><a class="header-anchor" href="#chu-can-shuo-ming">¶</a> 出参说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>类型/是否必传</th>
<th>说明</th>
<th>示例</th>
</tr>
</thead>
<tbody>
<tr>
<td>sendBackFee</td>
<td>Integer(8)/YES</td>
<td>送回费，单位：元（取件后取消订单，当取消费大于订单实付金额时，会产生送回费，需用户额外支付）</td>
<td>0</td>
</tr>
<tr>
<td>deductAmount</td>
<td>Integer(8)/YES</td>
<td>扣款金额，单位：元</td>
<td>0</td>
</tr>
<tr>
<td>abortType</td>
<td>String(2)/YES</td>
<td>取消发起人，详见下放枚举</td>
<td>1</td>
</tr>
<tr>
<td>punishType</td>
<td>String(2)/NO</td>
<td>取消责任人，详见下放枚举</td>
<td>1</td>
</tr>
<tr>
<td>abortReason</td>
<td>String(20)/YES</td>
<td>取消原因</td>
<td>客户主动取消订单</td>
</tr>
</tbody>
</table>
<h4 id="aborttypemei-ju" tabindex="-1"><a class="header-anchor" href="#aborttypemei-ju">¶</a> abortType枚举</h4>
<table>
<thead>
<tr>
<th>类型</th>
<th>描述</th>
</tr>
</thead>
<tbody>
<tr>
<td>1</td>
<td>客户发起取消</td>
</tr>
<tr>
<td>2</td>
<td>商家发起取消</td>
</tr>
<tr>
<td>3</td>
<td>骑手发起取消</td>
</tr>
<tr>
<td>10</td>
<td>系统自动发起取消</td>
</tr>
</tbody>
</table>
<h4 id="punishtypemei-ju" tabindex="-1"><a class="header-anchor" href="#punishtypemei-ju">¶</a> punishType枚举</h4>
<table>
<thead>
<tr>
<th>类型</th>
<th>描述</th>
</tr>
</thead>
<tbody>
<tr>
<td>1</td>
<td>因客户</td>
</tr>
<tr>
<td>2</td>
<td>因商家</td>
</tr>
<tr>
<td>3</td>
<td>因骑手</td>
</tr>
<tr>
<td>10</td>
<td>因系统自动取消</td>
</tr>
<tr>
<td>99</td>
<td>因其它</td>
</tr>
</tbody>
</table>
<h4 id="ding-dan-qu-xiao-liu-cheng-tu" tabindex="-1"><a class="header-anchor" href="#ding-dan-qu-xiao-liu-cheng-tu">¶</a> 订单取消流程图</h4>
<p><img src="./images/abortOrder/1706608160380.png" alt="1706608160380"></p>
<h4 id="ru-can-shi-li-datawei-ye-wu-can-shu-jsonxu-lie-hua-hou-chuan-ru" tabindex="-1"><a class="header-anchor" href="#ru-can-shi-li-datawei-ye-wu-can-shu-jsonxu-lie-hua-hou-chuan-ru">¶</a> 入参示例(data为业务参数，Json序列化后传入 )</h4>
<pre><code AAA="">{
    &quot;clientId&quot;: &quot;t8ljLE2mKPwEgAcVr&quot;,
    &quot;accessToken&quot;: &quot;d077bb79-4c8b-46a8-a06e-4a01a98bff6a&quot;,
    &quot;timestamp&quot;: &quot;1631862240982&quot;,
    &quot;sign&quot;: &quot;A1B4871AF792FDF449FE5291D4206D13&quot;,
    &quot;data&quot;: &quot;{\&quot;orderId\&quot;: \&quot;1751934464728399874\&quot;}&quot;
}
</code></pre>
<h4 id="ye-wu-can-shu-shi-li" tabindex="-1"><a class="header-anchor" href="#ye-wu-can-shu-shi-li">¶</a> 业务参数示例</h4>
<pre><code AAA="">{
    &quot;orderId&quot;: &quot;1751934464728399874&quot;,
}

</code></pre>
<h4 id="chu-can-shi-li" tabindex="-1"><a class="header-anchor" href="#chu-can-shi-li">¶</a> 出参示例</h4>
<pre><code AAA="">{
    &quot;msg&quot;: &quot;操作成功&quot;,
    &quot;code&quot;: 200,
    &quot;data&quot;: {
        &quot;punishAmount&quot;: 0,
        &quot;sendBackFee&quot;: 0,
        &quot;desc&quot;: &quot;频繁取消订单，可能会产生取消费。若因配送员原因取消，请联系配送员操作取消或投诉配送员&quot;
    },
    &quot;status&quot;: &quot;SUCCESS&quot;
}
</code></pre>
<h4 id="jie-kou-zhu-yi-dian" tabindex="-1"><a class="header-anchor" href="#jie-kou-zhu-yi-dian">¶</a> 接口注意点</h4>
<pre><code AAA="">

</code></pre>

      </section>
    </template>
    <script>
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div')}
var staticRenderFns = []

  
    const democomponentExport = {}
    return {
      render,
      staticRenderFns,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
   <style></style>
  