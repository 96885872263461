
    <template>
      <section class="content element-doc">
        <h1>商户授权</h1>
<demo-block>
        
        <template slot="source"><element-demo0 class="element-demo0"/></template>
        </demo-block><h2 id="1.zhun-bei-gong-zuo" tabindex="-1"><a class="header-anchor" href="#1.zhun-bei-gong-zuo">¶</a> 1.准备工作</h2>
<pre><code AAA="">1：确保已经以平台服务商身份登录驰鸽开放平台，获得了appKey和appSecret，查询路径：登录开放平台 -&gt; 账号中心 -&gt; 应用信息；
2：确保你的账号配置了redirectUrl（用来授权商户之后进行跳转，获取code等信息），检查路径：登录开放平台 -&gt; 账号中心 -&gt; 应用信息；
3：确保你的服务器可以连接 https://www.gdcgps.cn 和 test.gdcgps.cn可通过浏览器访问确认
</code></pre>
<h2 id="2.tian-jia-kai-fang-ping-tai-shou-quan-an-niu" tabindex="-1"><a class="header-anchor" href="#2.tian-jia-kai-fang-ping-tai-shou-quan-an-niu">¶</a> 2.添加开放平台授权按钮</h2>
<p>服务商角色在自己系统中添加「<strong>驰鸽</strong>」授权按钮 ,此步骤为在自己公司的系统中合适的地方开发「**驰鸽」授权按钮；</p>
<h3 id="2.1-pin-jie-shou-quan-di-zhi" tabindex="-1"><a class="header-anchor" href="#2.1-pin-jie-shou-quan-di-zhi">¶</a> 2.1 拼接授权地址</h3>
<pre><code AAA="">【生产环境】pc,h5版授权页面地址
 商户授权：
    https://www.gdcgps.cn/auth?response_type=code&amp;app_key=sseYlPL1Qo3j0lNfT&amp;redirect_url=https://support.qq.com/products/179257
 门店授权:
    https://www.gdcgps.cn/auth?response_type=code&amp;app_key=sseYlPL1Qo3j0lNfT&amp;redirect_url=https://support.qq.com/products/179257

【测试环境】pc,h5版授权页面地址
 商户授权:
    http://test.gdcgps.cn/auth?response_type=code&amp;app_key=ss2xpXL5NGhXIP7L2&amp;redirect_url=http%3a%2f%2flanyinbin.cn%2faa%2fcallBack
 门店授权:
    http://test.gdcgps.cn/auth?response_type=code&amp;app_key=ss2xpXL5NGhXIP7L2&amp;redirect_url=http%3a%2f%2flanyinbin.cn%2faa%2fcallBack
</code></pre>
<p>注意：</p>
<blockquote>
<ol>
<li>response_type为code</li>
<li>appKey换成自己的开发者ID（App Key），查询路径：登录开放平台 -&gt; 账号中心 -&gt; 应用信息</li>
<li>redirect_url和应用中心维护的redirectUrl一致</li>
<li>redirectUrl需要URLEncode编码</li>
</ol>
</blockquote>
<h3 id="2.2-huo-qu-shou-quan-code" tabindex="-1"><a class="header-anchor" href="#2.2-huo-qu-shou-quan-code">¶</a> 2.2 获取授权code</h3>
<ol>
<li>在上述授权页面，登录已经注册闪送的商户账号。（注意：在闪送的测试环境授权时，商户账号使用 “开放平台应用中心提供的授权账号密码”，如果看不到测试授权的商户账号密码，点击 “同步测试环境” 同步测试环境后就可以看到授权账号和授权账号密码了）</li>
<li>商户登录后可以看到当前账号的所有门店列表，商户可选择需要的门店进行&quot;门店授权&quot;，门店授权时只有授过权的门店能发单；商户也可对所有门店门店授权，点击&quot;全部授权&quot;代表所有门店均被授权，全部授权时所有门店都能发单(包括授权后新增的门店)</li>
<li>商户点击授权按钮后会跳转到平台服务商配置的redirecturl地址上，该地址会挂载参数code、state、thirdStoreId(三方门店ID) , shopId(商户ID)、闪送门店ID(storeId)、isAllStoreAuth（isAllStoreAuth为true,代表&quot;全部授权&quot;(即商户授权：即使后续商户新增门店，新增门店使用全部授权的accessToken也是生效的), 此时thirdStoreId和storeId不会传递。 isAllStoreAuth为false代表&quot;子门店授权&quot;，thirdStoreId和storeId会传递）。子门店授权时，商户可以根据返回的thirdStoreId和storeId记录对应关系，闪送不记录thirdStoreId和storeId的对应关系。</li>
<li>商家登录并授权后，跳转到指定的redirect_url，并跟上Authorization Code（注意此code会在1分钟内过期，只能使用1次）。例如：若redirect_url是：https://support.qq.com/products/179257，则跳转地址为：</li>
</ol>
<pre><code AAA="">门店授权时为：
https://support.qq.com/products/179257?code=520DD&amp;state=10583&amp;thirdStoreId=312313&amp;isAllStoreAuth=false&amp;storeId=1331275&amp;shopId=20000000000000348

全部授权时为：
https://support.qq.com/products/179257?code=520DD&amp;state=10583&amp;isAllStoreAuth=true&amp;shopId=20000000000000348
</code></pre>
<h4 id="pcban-ye-mian" tabindex="-1"><a class="header-anchor" href="#pcban-ye-mian">¶</a> pc版页面</h4>
<p><img src="./images/merchant_authorization/1704775864940.png" alt="1704775864940"></p>
<p><img src="./images/merchant_authorization/1704776092056.png" alt="1704776092056"></p>
<h2 id="3.shou-quan-jie-kou" tabindex="-1"><a class="header-anchor" href="#3.shou-quan-jie-kou">¶</a> 3.授权接口</h2>
<h3 id="3.1-tong-guo-codehuo-qu-accesstokenyu-refreshtoken" tabindex="-1"><a class="header-anchor" href="#3.1-tong-guo-codehuo-qu-accesstokenyu-refreshtoken">¶</a> 3.1 通过Code获取AccessToken与RefreshToken</h3>
<blockquote>
<p>商户授权确认时会发送code,通过code换取AccessToken与RefreshToken。并保存RefreshToken，当AccessToken过期后(72小时)，可通过RefreshToken刷新AccessToken。如果RefreshToken丢失，只能发起商户重新授权通过code换取。</p>
</blockquote>
<h5 id="qing-qiu-url" tabindex="-1"><a class="header-anchor" href="#qing-qiu-url">¶</a> 请求url：</h5>
<p>/open/api/token</p>
<h4 id="qing-qiu-fang-shi" tabindex="-1"><a class="header-anchor" href="#qing-qiu-fang-shi">¶</a> 请求方式</h4>
<p>post请求
Content-Type：application/x-www-form-urlencoded</p>
<h4 id="qian-ming-ji-suan" tabindex="-1"><a class="header-anchor" href="#qian-ming-ji-suan">¶</a> 签名计算</h4>
<p><a href="/docs/developers_guide">请参考开发指南</a></p>
<h4 id="ru-can-shuo-ming" tabindex="-1"><a class="header-anchor" href="#ru-can-shuo-ming">¶</a> 入参说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>字段描述</th>
<th>类型/是否必传</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>clientId</td>
<td>ss6DzHy9GvHB46Jgo</td>
<td>String/YES</td>
<td>开发者ID(appKey)</td>
</tr>
<tr>
<td>code</td>
<td>juwAxvhYCQwPWegule</td>
<td>String/YES</td>
<td>授权到跳转页获取的code</td>
</tr>
</tbody>
</table>
<h4 id="ru-can-shi-li" tabindex="-1"><a class="header-anchor" href="#ru-can-shi-li">¶</a> 入参示例</h4>
<pre><code AAA="">{
    clientId:ss6DzHy9GvHB46Jgo
    code:sTiuBAIEBRYrfBNEMJ    
}

</code></pre>
<h4 id="chu-can-shi-li" tabindex="-1"><a class="header-anchor" href="#chu-can-shi-li">¶</a> 出参示例</h4>
<pre><code AAA="json" class="language-json">{
 &quot;msg&quot;: &quot;操作成功&quot;,
 &quot;code&quot;: 200,
 &quot;data&quot;: {
    &quot;expiresIn&quot;: 259220,
    &quot;accessToken&quot;: &quot;a2b2a198-932f-4628-ba15-d90d9ae94db9&quot;,
    &quot;refreshToken&quot;: &quot;0bfd1b5f-cd85-4d3a-8b87-67aed59244f5&quot;
 },
 &quot;status&quot;: &quot;SUCCESS&quot;
 }
</code></pre>
<h3 id="3.2-refreshtokenshua-xin-accesstoken" tabindex="-1"><a class="header-anchor" href="#3.2-refreshtokenshua-xin-accesstoken">¶</a> 3.2 RefreshToken刷新AccessToken</h3>
<blockquote>
<p>每个商户授权给服务商之后，会生成AccessToken。服务商通过AccessToken代商户发起操作。AccessToken会过期（72小时），所以服务商开发时应该缓存每个商户的AccessToken，并在过期前通过RefreshToken刷新AccessToken</p>
</blockquote>
<h4 id="qing-qiu-url-1" tabindex="-1"><a class="header-anchor" href="#qing-qiu-url-1">¶</a> 请求url：</h4>
<p>/open/api/refresh_token</p>
<h4 id="qing-qiu-fang-shi-1" tabindex="-1"><a class="header-anchor" href="#qing-qiu-fang-shi-1">¶</a> 请求方式</h4>
<p>post请求</p>
<p>Content-Type：application/x-www-form-urlencoded</p>
<h4 id="qian-ming-ji-suan-1" tabindex="-1"><a class="header-anchor" href="#qian-ming-ji-suan-1">¶</a> 签名计算</h4>
<p><a href="/docs/developers_guide">请参考开发指南</a></p>
<h4 id="ru-can-shuo-ming-1" tabindex="-1"><a class="header-anchor" href="#ru-can-shuo-ming-1">¶</a> 入参说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>字段描述</th>
<th>类型/是否必传</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>clientId</td>
<td>ss6DzHy9GvHB46Jgo</td>
<td>String/YES</td>
<td>开发者ID(appKey)</td>
</tr>
<tr>
<td>sign</td>
<td>AD1D5AC34875897302C6726438C5A528</td>
<td>String/YES</td>
<td>签名</td>
</tr>
<tr>
<td>timestamp</td>
<td>1631862240982</td>
<td>String/YES</td>
<td>时间戳</td>
</tr>
<tr>
<td>data</td>
<td>{“refreshToken”:“53521dad-60d2-4bf7-a81e-3882c2721bf1”}</td>
<td>String/YES</td>
<td>业务参数</td>
</tr>
</tbody>
</table>
<h4 id="ru-can-shi-li-1" tabindex="-1"><a class="header-anchor" href="#ru-can-shi-li-1">¶</a> 入参示例</h4>
<pre><code AAA="">{
    &quot;clientId&quot;:&quot;t8ljLE2mKPwEgAcVr&quot;,
    &quot;timestamp&quot;:&quot;1631862240982&quot;,
    &quot;data&quot;:&quot;{\&quot;refreshToken\&quot;: \&quot;0c98ed75-e4d7-48ce-9d51-77a0ec7ad88b\&quot;}&quot;,
    &quot;sign&quot;:&quot;82AA9A7AA677CF60A54B31FE6D522B5F&quot;
}
</code></pre>
<h4 id="chu-can-shi-li-1" tabindex="-1"><a class="header-anchor" href="#chu-can-shi-li-1">¶</a> 出参示例</h4>
<pre><code AAA="">{
    &quot;msg&quot;: &quot;操作成功&quot;,
    &quot;code&quot;: 200,
    &quot;data&quot;: {
        &quot;expiresIn&quot;: 259220,
        &quot;accessToken&quot;: &quot;d7180c82-47c8-4350-a15d-054fd4ef0dc4&quot;
    },
    &quot;status&quot;: &quot;SUCCESS&quot;
}
</code></pre>
<h3 id="3.3-qu-xiao-shou-quan-jie-kou" tabindex="-1"><a class="header-anchor" href="#3.3-qu-xiao-shou-quan-jie-kou">¶</a> 3.3 取消授权接口</h3>
<h4 id="qing-qiu-url-2" tabindex="-1"><a class="header-anchor" href="#qing-qiu-url-2">¶</a> 请求url：</h4>
<p>/openapi/oauth/cancel</p>
<h4 id="qing-qiu-fang-shi-2" tabindex="-1"><a class="header-anchor" href="#qing-qiu-fang-shi-2">¶</a> 请求方式</h4>
<p>post请求</p>
<p>Content-Type：application/x-www-form-urlencoded</p>
<h4 id="qian-ming-ji-suan-2" tabindex="-1"><a class="header-anchor" href="#qian-ming-ji-suan-2">¶</a> 签名计算</h4>
<p><a href="/guidance/developers_guide">请参考开发指南</a></p>
<h4 id="ru-can-shuo-ming-2" tabindex="-1"><a class="header-anchor" href="#ru-can-shuo-ming-2">¶</a> 入参说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>字段描述</th>
<th>类型/是否必传</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>clientId</td>
<td>ss6DzHy9GvHB46Jgo</td>
<td>String/YES</td>
<td>开发者ID(appKey)</td>
</tr>
<tr>
<td>sign</td>
<td>6355317A7C5047FA13C0ECC7E58B2C72</td>
<td>String/YES</td>
<td>签名</td>
</tr>
<tr>
<td>timestamp</td>
<td>1631862240982</td>
<td>String/YES</td>
<td>时间戳</td>
</tr>
<tr>
<td>data</td>
<td>{“accessToken”:“64850c4c-06fe-46fa-b30a-f680b193fe15”}</td>
<td>String/YES</td>
<td>业务参数</td>
</tr>
</tbody>
</table>
<h4 id="ru-can-shi-li-2" tabindex="-1"><a class="header-anchor" href="#ru-can-shi-li-2">¶</a> 入参示例</h4>
<pre><code AAA="">{
    &quot;clientId&quot;:&quot;t8ljLE2mKPwEgAcVr&quot;,
    &quot;timestamp&quot;:&quot;1631862240982&quot;,
    &quot;data&quot;:&quot;{\&quot;accessToken\&quot;: \&quot;d7180c82-47c8-4350-a15d-054fd4ef0dc4\&quot;}&quot;,
    &quot;sign&quot;:&quot;B5DDB48D6DE7AA467FD816BDA51A2177&quot;
}
</code></pre>
<h4 id="chu-can-shi-li-2" tabindex="-1"><a class="header-anchor" href="#chu-can-shi-li-2">¶</a> 出参示例</h4>
<pre><code AAA="">{
    &quot;msg&quot;: &quot;取消授权成功&quot;,
    &quot;code&quot;: 200,
    &quot;status&quot;: &quot;SUCCESS&quot;
}
</code></pre>

      </section>
    </template>
    <script>
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div')}
var staticRenderFns = []

  
    const democomponentExport = {}
    return {
      render,
      staticRenderFns,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
   <style></style>
  