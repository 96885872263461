
    <template>
      <section class="content element-doc">
        <h3 id="cha-xun-kai-tong-cheng-shi" tabindex="-1"><a class="header-anchor" href="#cha-xun-kai-tong-cheng-shi">¶</a> 查询开通城市</h3>
<demo-block>
        
        <template slot="source"><element-demo0 class="element-demo0"/></template>
        </demo-block><pre><code AAA="">接口说明：可以用来判断城市是否开通配送服务，供订单计费使用。开通城市基本不变，建议存储到本地并且定时查询进行更新。
</code></pre>
<h4 id="qing-qiu-url" tabindex="-1"><a class="header-anchor" href="#qing-qiu-url">¶</a> 请求url</h4>
<p><code>GET</code>  /open/api/openCitiesLists</p>
<h4 id="jie-kou-xie-yi-yi-ji-qian-ming-ji-suan" tabindex="-1"><a class="header-anchor" href="#jie-kou-xie-yi-yi-ji-qian-ming-ji-suan">¶</a> 接口协议以及签名计算</h4>
<p><a href="/docs/merchant_authorization">请参考开发指南</a></p>
<h4 id="ce-shi-lian-diao" tabindex="-1"><a class="header-anchor" href="#ce-shi-lian-diao">¶</a> 测试联调</h4>
<p><a href="/docs/testing_and_debugging">联调说明</a></p>
<h4 id="ru-can-shuo-ming" tabindex="-1"><a class="header-anchor" href="#ru-can-shuo-ming">¶</a> 入参说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>示例</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>clientId</td>
<td>ss6DzHy9GvHB46Jgo</td>
<td>App-key</td>
</tr>
<tr>
<td>accessToken</td>
<td>70882965-5e51-463f-a746-7bcf1b3f1c34</td>
<td><a href="/docs/merchant_authorization">通过商户授权获取</a></td>
</tr>
<tr>
<td>timestamp</td>
<td>1631862240982</td>
<td>毫秒级时间戳</td>
</tr>
<tr>
<td>sign</td>
<td>955E0C0DDAF87B9D26E0171BEF65C0D8</td>
<td>签名</td>
</tr>
</tbody>
</table>
<h4 id="chu-can-shuo-ming" tabindex="-1"><a class="header-anchor" href="#chu-can-shuo-ming">¶</a> 出参说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>类型/是否必传</th>
<th>说明</th>
<th>示例</th>
</tr>
</thead>
<tbody>
<tr>
<td>index</td>
<td>String(1)/YES</td>
<td>城市首字母</td>
<td>A</td>
</tr>
<tr>
<td>name</td>
<td>String(20)/YES</td>
<td>城市名称</td>
<td>鞍山市</td>
</tr>
<tr>
<td>id</td>
<td>Long(20)/YES</td>
<td>城市ID</td>
<td>2130</td>
</tr>
</tbody>
</table>
<h4 id="ru-can-shi-li" tabindex="-1"><a class="header-anchor" href="#ru-can-shi-li">¶</a> 入参示例</h4>
<pre><code AAA="">{
    clientId:ss6DzHy9GvHB46Jgo
    accessToken:70882965-5e51-463f-a746-7bcf1b3f1c34
    timestamp:1631862240982
    sign:955E0C0DDAF87B9D26E0171BEF65C0D8    
}

</code></pre>
<h4 id="chu-can-shi-li" tabindex="-1"><a class="header-anchor" href="#chu-can-shi-li">¶</a> 出参示例</h4>
<pre><code AAA="">{
    &quot;msg&quot;: &quot;操作成功&quot;,
    &quot;code&quot;: 200,
    &quot;data&quot;: [
        {
            &quot;id&quot;: &quot;1659198396512079874&quot;,
            &quot;provinceName&quot;: &quot;广东省&quot;,
            &quot;cityName&quot;: &quot;江门市&quot;,
            &quot;districtName&quot;: &quot;开平市&quot;
        },
        {
            &quot;id&quot;: &quot;1637395650766249985&quot;,
            &quot;provinceName&quot;: &quot;广东省&quot;,
            &quot;cityName&quot;: &quot;深圳市&quot;,
            &quot;districtName&quot;: &quot;南山区&quot;
        },
        {
            &quot;id&quot;: &quot;1666108306462085122&quot;,
            &quot;provinceName&quot;: &quot;广东省&quot;,
            &quot;cityName&quot;: &quot;深圳市&quot;,
            &quot;districtName&quot;: &quot;宝安区&quot;
        },
        {
            &quot;id&quot;: &quot;1637414875496980481&quot;,
            &quot;provinceName&quot;: &quot;广东省&quot;,
            &quot;cityName&quot;: &quot;深圳市&quot;,
            &quot;districtName&quot;: &quot;福田区&quot;
        },
        {
            &quot;id&quot;: &quot;1711001478988042241&quot;,
            &quot;provinceName&quot;: &quot;广东省&quot;,
            &quot;cityName&quot;: &quot;阳江市&quot;,
            &quot;districtName&quot;: &quot;江城区&quot;
        },
        {
            &quot;id&quot;: &quot;1637414359614365698&quot;,
            &quot;provinceName&quot;: &quot;广东省&quot;,
            &quot;cityName&quot;: &quot;阳江市&quot;,
            &quot;districtName&quot;: &quot;阳西县&quot;
        }
    ],
    &quot;status&quot;: &quot;SUCCESS&quot;
}
</code></pre>

      </section>
    </template>
    <script>
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div')}
var staticRenderFns = []

  
    const democomponentExport = {}
    return {
      render,
      staticRenderFns,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
   <style></style>
  