// import parseTime, formatTime and set to filter
export { formatTime } from "@/utils";
import Config from '@/config/config';

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + "s";
}
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null;
  }
  const format = pattern || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
    console.log("1", time);
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
      console.log("2", time);
    } else if (typeof time === "string") {
      // time = time.replace(new RegExp(/-/gm), '/');
      console.log("3", time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
      console.log("4", time);
    }
    date = new Date(time);
    console.log("5", date);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}
/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), " minute");
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), " hour");
  } else {
    return pluralize(~~(between / 86400), " day");
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1e18, symbol: "E" },
    { value: 1e15, symbol: "P" },
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "G" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "k" },
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value)
          .toFixed(digits)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[i].symbol
      );
    }
  }
  return num.toString();
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0)
    .toString()
    .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","));
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function money(val) {
  val = Number(val).toFixed(2);
  // let ThousandNum =  val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // return ThousandNum
  return "￥" + val;

}
export function img(val) {
  if (val == null) {
    return null;
  }
  if (val == "undefined") {
    return null;
  }
  if (val.indexOf("|") > val.length - 5) {
    var per = val.substring(0, val.indexOf("."));
    var filetype = val.substring(val.indexOf("."), val.indexOf("|"));
    var imgsize = val.substring(val.lastIndexOf("|") + 1, val.length);
    val = per + "x" + imgsize + filetype;
    console.log(val);
  }
  let img = null;
  const i = val.indexOf("//");
  if (i === -1) {
    img = Config.baseUrl + val;
  } else {
    img = val;
  }
  return img;
}
export function img300(val) {
  if (val == null) {
    return null;
  }
  if (val == "undefined") {
    return null;
  }
  if (val.indexOf("|") > val.length - 5) {
    var per = val.substring(0, val.indexOf("."));
    var filetype = val.substring(val.indexOf("."), val.indexOf("|"));
    var imgsize = val.substring(val.lastIndexOf("|") + 1, val.length);
    val = per + "x" + imgsize + filetype;
    console.log(val);
  }
  let img = null;
  const i = val.indexOf("//");
  if (i === -1) {
    img = Config.baseUrl + val;
  } else {
    img = val;
  }
  var lastIndex = img.lastIndexOf(".");
  var pathName = img.substring(0, lastIndex);
  var extName = img.substring(lastIndex);
  img = pathName + "_" + 300 + extName;
  return img;
}

export function avatar(val) {
  let _avatar = null;
  // console.log("my.vue avatar:", this.user.avatar);
  if (val == null) {
    _avatar = Config.baseUrl + "/uploads/avatar/avatar.jpg";
  } else {
    const i = val.indexOf("//");
    if (i === -1) {
      _avatar = Config.baseUrl + val;
    } else {
      _avatar = val;
    }
  }
  return _avatar;
}
export function sub(val) {
  if (val == null || val == undefined) {
    return;
  }
  return "***" + val.substring(7, val.length);
}
export function hidephone(val) {
  if (val == null || val == undefined) {
    return;
  }
  return val.substring(0, 3) + "****" + val.substring(7, val.length);
}
export function sub5(val) {
  if (val == null || val == undefined) {
    return;
  }
  if (val.length < 5) {
    return val;
  } else {
    return val.substring(0, 4) + "..";
  }
}
export function sub3(val) {
  if (val == null || val == undefined) {
    return;
  }
  if (val.length < 3) {
    return val;
  } else {
    return val.substring(0, 2) + "..";
  }
}
// AAA***AAA
export function lessStr(val, long = 3) {
  if (val == null) {
    return val;
  }
  if (val.length > 2 * long) {
    return (
      val.substring(0, long) +
      "***" +
      val.substring(val.length - long, val.length)
    );
  } else {
    return val;
  }
}
