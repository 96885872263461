
    <template>
      <section class="content element-doc">
        <h3 id="fen-ye-cha-xun-shang-hu-dian-pu" tabindex="-1"><a class="header-anchor" href="#fen-ye-cha-xun-shang-hu-dian-pu">¶</a> 分页查询商户店铺</h3>
<demo-block>
        
        <template slot="source"><element-demo0 class="element-demo0"/></template>
        </demo-block><pre><code AAA="">接口说明：
1、用来查询授权商户下面的店铺列表
</code></pre>
<h4 id="qing-qiu-url" tabindex="-1"><a class="header-anchor" href="#qing-qiu-url">¶</a> 请求url</h4>
<p><code>POST</code>  /open/api/store/storePage</p>
<h4 id="qian-ming-ji-suan" tabindex="-1"><a class="header-anchor" href="#qian-ming-ji-suan">¶</a> 签名计算</h4>
<p><a href="/docs/developers_guide">请参考开发指南</a></p>
<h4 id="ce-shi-lian-diao" tabindex="-1"><a class="header-anchor" href="#ce-shi-lian-diao">¶</a> 测试联调</h4>
<p><a href="/docs/testing_and_debugging">联调说明</a></p>
<h4 id="ru-can-shuo-ming" tabindex="-1"><a class="header-anchor" href="#ru-can-shuo-ming">¶</a> 入参说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>示例</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>clientId</td>
<td>ss6DzHy9GvHB46Jgo</td>
<td>App-key</td>
</tr>
<tr>
<td>accessToken</td>
<td>70882965-5e51-463f-a746-7bcf1b3f1c34</td>
<td><a href="/docs/merchant_authorization">通过商户授权获取</a></td>
</tr>
<tr>
<td>timestamp</td>
<td>1631862240982</td>
<td>毫秒级时间戳</td>
</tr>
<tr>
<td>data</td>
<td>参考入参示例</td>
<td>业务入参</td>
</tr>
<tr>
<td>sign</td>
<td>63068EBC3C28B7F822F74DAB874D4027</td>
<td>签名计算参考 <a href="/docs/developers_guide">开发指南</a></td>
</tr>
</tbody>
</table>
<h4 id="datacan-shu-shuo-ming-ye-wu-bu-chuan-can-shu-shi-qing-chuan" tabindex="-1"><a class="header-anchor" href="#datacan-shu-shuo-ming-ye-wu-bu-chuan-can-shu-shi-qing-chuan">¶</a> data参数说明 (业务不传参数时请传“{}”)</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>字段描述</th>
<th>类型/是否必传</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>current</td>
<td>页面</td>
<td>Integer(8)/NO</td>
<td>查询第几页,默认:1</td>
</tr>
<tr>
<td>size</td>
<td>每页数据量</td>
<td>Integer(8)/NO</td>
<td>默认:10</td>
</tr>
<tr>
<td>ascs</td>
<td>每页数据量</td>
<td>String(8)/NO</td>
<td>升序字段名 ascs与descs只需要写1个,</td>
</tr>
<tr>
<td>descs</td>
<td>每页数据量</td>
<td>String(8)/NO</td>
<td>降序字段名 ascs与descs只需要写1个,</td>
</tr>
</tbody>
</table>
<h4 id="chu-can-shuo-ming" tabindex="-1"><a class="header-anchor" href="#chu-can-shuo-ming">¶</a> 出参说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>类型/是否必传</th>
<th>说明</th>
<th>示例</th>
</tr>
</thead>
<tbody>
<tr>
<td>id</td>
<td>Integer(8)/YES</td>
<td>店铺ID</td>
<td>1649719048086654977</td>
</tr>
<tr>
<td>merchantId</td>
<td>Integer(32)/YES</td>
<td>商户ID</td>
<td>1649332296196976641</td>
</tr>
<tr>
<td>userId</td>
<td>Integer(32)/YES</td>
<td>用户ID</td>
<td>1649332296196976641</td>
</tr>
<tr>
<td>storeName</td>
<td>String(32)/YES</td>
<td>店铺名称</td>
<td>1649332296196976641</td>
</tr>
<tr>
<td>province</td>
<td>String(8)/YES</td>
<td>省份</td>
<td>广东省</td>
</tr>
<tr>
<td>city</td>
<td>String(8)/YES</td>
<td>城市</td>
<td>深圳市</td>
</tr>
<tr>
<td>district</td>
<td>String(8)/YES</td>
<td>区县</td>
<td>宝安区</td>
</tr>
<tr>
<td>address</td>
<td>String(32)/YES</td>
<td>地址</td>
<td>烤鸡宝</td>
</tr>
<tr>
<td>addressDetail</td>
<td>String(32)/YES</td>
<td>地址详情</td>
<td>宝安区创业一路1号</td>
</tr>
<tr>
<td>facade</td>
<td>Integer(8)/NO</td>
<td>门面照片（请补全域名'https://api.gdcgps.cn'使用）</td>
<td>&quot;/profile/../a.jpg&quot;</td>
</tr>
<tr>
<td>lat</td>
<td>Double/YES</td>
<td>纬度</td>
<td>22.554999</td>
</tr>
<tr>
<td>lng</td>
<td>Double/YES</td>
<td>经度</td>
<td>113.883805</td>
</tr>
<tr>
<td>status</td>
<td>Integer(8)/YES</td>
<td>认证状态 0：审核中；1：已认证；2：不通过</td>
<td>1</td>
</tr>
<tr>
<td>storeType</td>
<td>Integer(8)/YES</td>
<td>店铺类型（参考下面店铺物品类型标签枚举）</td>
<td>1</td>
</tr>
<tr>
<td>name</td>
<td>String(32)/YES</td>
<td>联系人姓名</td>
<td>张三</td>
</tr>
<tr>
<td>phone</td>
<td>String(32)/YES</td>
<td>联系人电话</td>
<td>13888888888</td>
</tr>
</tbody>
</table>
<h4 id="dian-pu-wu-pin-lei-xing-biao-qian-mei-ju-storetype" tabindex="-1"><a class="header-anchor" href="#dian-pu-wu-pin-lei-xing-biao-qian-mei-ju-storetype">¶</a> 店铺物品类型标签枚举（storeType）</h4>
<table>
<thead>
<tr>
<th>物品类型标签</th>
<th>物品类型标签名称</th>
</tr>
</thead>
<tbody>
<tr>
<td>1</td>
<td>文件</td>
</tr>
<tr>
<td>2</td>
<td>证件</td>
</tr>
<tr>
<td>3</td>
<td>数码</td>
</tr>
<tr>
<td>5</td>
<td>蛋糕</td>
</tr>
<tr>
<td>6</td>
<td>餐饮</td>
</tr>
<tr>
<td>7</td>
<td>鲜花</td>
</tr>
<tr>
<td>8</td>
<td>海鲜</td>
</tr>
<tr>
<td>9</td>
<td>水果</td>
</tr>
<tr>
<td>10</td>
<td>药品</td>
</tr>
<tr>
<td>11</td>
<td>服饰</td>
</tr>
<tr>
<td>14</td>
<td>汽配</td>
</tr>
<tr>
<td>15</td>
<td>其他</td>
</tr>
</tbody>
</table>
<h4 id="ru-can-shi-li-datawei-ye-wu-can-shu-jsonxu-lie-hua-hou-chuan-ru" tabindex="-1"><a class="header-anchor" href="#ru-can-shi-li-datawei-ye-wu-can-shu-jsonxu-lie-hua-hou-chuan-ru">¶</a> 入参示例(data为业务参数，Json序列化后传入 )</h4>
<pre><code AAA="">{
    &quot;clientId&quot;: &quot;t8ljLE2mKPwEgAcVr&quot;,
    &quot;accessToken&quot;: &quot;70882965-5e51-463f-a746-7bcf1b3f1c34&quot;,
    &quot;timestamp&quot;: &quot;1631862240982&quot;,
    &quot;sign&quot;: &quot;F439B21AA8A1EC320C5690694180FFD0&quot;,
    &quot;data&quot;: &quot;{}&quot;
}
</code></pre>
<h4 id="ye-wu-can-shu-shi-li" tabindex="-1"><a class="header-anchor" href="#ye-wu-can-shu-shi-li">¶</a> 业务参数示例</h4>
<pre><code AAA="">{
      current: 1,
      size: 10,
      descs: 'create_time'
}
或者
{

}

</code></pre>
<h4 id="chu-can-shi-li" tabindex="-1"><a class="header-anchor" href="#chu-can-shi-li">¶</a> 出参示例</h4>
<pre><code AAA="">{
    &quot;msg&quot;: &quot;操作成功&quot;,
    &quot;code&quot;: 200,
    &quot;data&quot;: {
        &quot;records&quot;: [
            {
                &quot;id&quot;: &quot;1649719048086654977&quot;,
                &quot;merchantId&quot;: &quot;1649332296196976641&quot;,
                &quot;userId&quot;: &quot;1617518469018877953&quot;,
                &quot;storeName&quot;: &quot;烤鸡宝安店&quot;,
                &quot;province&quot;: &quot;广东省&quot;,
                &quot;city&quot;: &quot;深圳市&quot;,
                &quot;district&quot;: &quot;宝安区&quot;,
                &quot;address&quot;: &quot;创业一路&quot;,
                &quot;addressDetail&quot;: &quot;广东省深圳市宝安区创业一路1号&quot;,
                &quot;lat&quot;: 22.554999,
                &quot;lng&quot;: 113.883805,
                &quot;name&quot;: &quot;张三&quot;,
                &quot;phone&quot;: &quot;15813144458&quot;,
                &quot;frontIdCard&quot;: null,
                &quot;reverseIdCard&quot;: null,
                &quot;businessLicense&quot;: null,
                &quot;facade&quot;: &quot;/profile/mini/2023/04/22/1e562b37-7a44-4816-93a8-cd2891d38833.jpg&quot;,
                &quot;status&quot;: null,
                &quot;cargoType&quot;: &quot;&quot;,
                &quot;createTime&quot;: &quot;2023-04-22T10:17:26.000+0800&quot;,
                &quot;updateTime&quot;: &quot;2024-02-04T09:57:33.000+0800&quot;,
                &quot;userInfo&quot;: [],
                &quot;myDefault&quot;: false,
                &quot;delFlag&quot;: &quot;0&quot;
            },
        ],
        &quot;total&quot;: 3,
        &quot;size&quot;: 10,
        &quot;current&quot;: 1,
        &quot;orders&quot;: [],
        &quot;optimizeCountSql&quot;: true,
        &quot;hitCount&quot;: false,
        &quot;searchCount&quot;: true,
        &quot;pages&quot;: 1
    },
    &quot;status&quot;: &quot;SUCCESS&quot;
}
</code></pre>
<h4 id="jie-kou-zhu-yi-dian" tabindex="-1"><a class="header-anchor" href="#jie-kou-zhu-yi-dian">¶</a> 接口注意点</h4>
<pre><code AAA="">

</code></pre>

      </section>
    </template>
    <script>
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div')}
var staticRenderFns = []

  
    const democomponentExport = {}
    return {
      render,
      staticRenderFns,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
   <style></style>
  