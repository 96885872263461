/**
* @param { * } file input选择后返回的file对象
*/

export const compressImage = (file) => {
  // 参数file,是通过input 选择本地文件获取的
  return new Promise((resolve, reject) => {
    var limit = 2 // 限制文件大小
    const { type, name, size } = file
    if (file.size / 1024 / 1024 < limit) { // 小于10直接返回
      resolve({ type: type, compressFile: file })
      console.log('不压缩：', size / 1024 / 1024, 'M')
      return
    }
    console.log('压缩前：', size / 1024 / 1024, 'M')
    const img = new Image()
    // 创建一个reader实例
    const reader = new FileReader()
    // 读取拿到的文件
    reader.readAsDataURL(file)
    reader.onload = (e) => {
      // 文件加载成功后去转成Img对象，为了拿到图片的原始宽高
      img.src = e.target.result
      img.onload = () => {
        // 创建画布canvas
        const canvas = document.createElement('canvas')
        const content = canvas.getContext('2d')
        // 设置画布的宽高
        canvas.width = img.width * limit / (size / 1024 / 1024) // 需要压缩到的图片宽度
        canvas.height = img.width * (img.height / img.width) * limit / (size / 1024 / 1024)
        // 将图片画在画布上
        content.drawImage(img, 0, 0, canvas.width, canvas.height)
        // 画布转成blob格式的图片
        canvas.toBlob((blob) => {
          // blob 格式的图片 转成file对象，这里主要看接口支不支持blob格式的文件上传，如果支持就没有必要转
          const file = new File([blob], name, { name: name, type: blob.type, size: blob.size })
          console.log('压缩后：', file.size / 1024 / 1024, 'M,压缩率：', limit / (size / 1024 / 1024))
          resolve({ type: type, compressFile: file })
        }, `image/${type.split('/')[1]}`, 0.7) // 0.7 是文件压缩程度
      }
    }
  })
}
