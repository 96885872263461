var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content element-doc"},[_c('h1',[_vm._v("商户授权")]),_c('demo-block',[_c('template',{slot:"source"},[_c('element-demo0',{staticClass:"element-demo0"})],1)],2),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('p',[_vm._v("注意：")]),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_c('p',[_vm._v("/open/api/token")]),_vm._m(17),_c('p',[_vm._v("post请求 Content-Type：application/x-www-form-urlencoded")]),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24),_vm._m(25),_vm._m(26),_vm._m(27),_vm._m(28),_c('p',[_vm._v("/open/api/refresh_token")]),_vm._m(29),_c('p',[_vm._v("post请求")]),_c('p',[_vm._v("Content-Type：application/x-www-form-urlencoded")]),_vm._m(30),_vm._m(31),_vm._m(32),_vm._m(33),_vm._m(34),_vm._m(35),_vm._m(36),_vm._m(37),_vm._m(38),_vm._m(39),_c('p',[_vm._v("/openapi/oauth/cancel")]),_vm._m(40),_c('p',[_vm._v("post请求")]),_c('p',[_vm._v("Content-Type：application/x-www-form-urlencoded")]),_vm._m(41),_vm._m(42),_vm._m(43),_vm._m(44),_vm._m(45),_vm._m(46),_vm._m(47),_vm._m(48)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{attrs:{"id":"1.zhun-bei-gong-zuo","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#1.zhun-bei-gong-zuo"}},[_vm._v("¶")]),_vm._v(" 1.准备工作")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("1：确保已经以平台服务商身份登录驰鸽开放平台，获得了appKey和appSecret，查询路径：登录开放平台 -> 账号中心 -> 应用信息；\n2：确保你的账号配置了redirectUrl（用来授权商户之后进行跳转，获取code等信息），检查路径：登录开放平台 -> 账号中心 -> 应用信息；\n3：确保你的服务器可以连接 https://www.gdcgps.cn 和 test.gdcgps.cn可通过浏览器访问确认\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{attrs:{"id":"2.tian-jia-kai-fang-ping-tai-shou-quan-an-niu","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#2.tian-jia-kai-fang-ping-tai-shou-quan-an-niu"}},[_vm._v("¶")]),_vm._v(" 2.添加开放平台授权按钮")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("服务商角色在自己系统中添加「"),_c('strong',[_vm._v("驰鸽")]),_vm._v("」授权按钮 ,此步骤为在自己公司的系统中合适的地方开发「**驰鸽」授权按钮；")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"2.1-pin-jie-shou-quan-di-zhi","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#2.1-pin-jie-shou-quan-di-zhi"}},[_vm._v("¶")]),_vm._v(" 2.1 拼接授权地址")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("【生产环境】pc,h5版授权页面地址\n 商户授权：\n    https://www.gdcgps.cn/auth?response_type=code&app_key=sseYlPL1Qo3j0lNfT&redirect_url=https://support.qq.com/products/179257\n 门店授权:\n    https://www.gdcgps.cn/auth?response_type=code&app_key=sseYlPL1Qo3j0lNfT&redirect_url=https://support.qq.com/products/179257\n\n【测试环境】pc,h5版授权页面地址\n 商户授权:\n    http://test.gdcgps.cn/auth?response_type=code&app_key=ss2xpXL5NGhXIP7L2&redirect_url=http%3a%2f%2flanyinbin.cn%2faa%2fcallBack\n 门店授权:\n    http://test.gdcgps.cn/auth?response_type=code&app_key=ss2xpXL5NGhXIP7L2&redirect_url=http%3a%2f%2flanyinbin.cn%2faa%2fcallBack\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('blockquote',[_c('ol',[_c('li',[_vm._v("response_type为code")]),_c('li',[_vm._v("appKey换成自己的开发者ID（App Key），查询路径：登录开放平台 -> 账号中心 -> 应用信息")]),_c('li',[_vm._v("redirect_url和应用中心维护的redirectUrl一致")]),_c('li',[_vm._v("redirectUrl需要URLEncode编码")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"2.2-huo-qu-shou-quan-code","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#2.2-huo-qu-shou-quan-code"}},[_vm._v("¶")]),_vm._v(" 2.2 获取授权code")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("在上述授权页面，登录已经注册闪送的商户账号。（注意：在闪送的测试环境授权时，商户账号使用 “开放平台应用中心提供的授权账号密码”，如果看不到测试授权的商户账号密码，点击 “同步测试环境” 同步测试环境后就可以看到授权账号和授权账号密码了）")]),_c('li',[_vm._v("商户登录后可以看到当前账号的所有门店列表，商户可选择需要的门店进行\"门店授权\"，门店授权时只有授过权的门店能发单；商户也可对所有门店门店授权，点击\"全部授权\"代表所有门店均被授权，全部授权时所有门店都能发单(包括授权后新增的门店)")]),_c('li',[_vm._v("商户点击授权按钮后会跳转到平台服务商配置的redirecturl地址上，该地址会挂载参数code、state、thirdStoreId(三方门店ID) , shopId(商户ID)、闪送门店ID(storeId)、isAllStoreAuth（isAllStoreAuth为true,代表\"全部授权\"(即商户授权：即使后续商户新增门店，新增门店使用全部授权的accessToken也是生效的), 此时thirdStoreId和storeId不会传递。 isAllStoreAuth为false代表\"子门店授权\"，thirdStoreId和storeId会传递）。子门店授权时，商户可以根据返回的thirdStoreId和storeId记录对应关系，闪送不记录thirdStoreId和storeId的对应关系。")]),_c('li',[_vm._v("商家登录并授权后，跳转到指定的redirect_url，并跟上Authorization Code（注意此code会在1分钟内过期，只能使用1次）。例如：若redirect_url是：https://support.qq.com/products/179257，则跳转地址为：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("门店授权时为：\nhttps://support.qq.com/products/179257?code=520DD&state=10583&thirdStoreId=312313&isAllStoreAuth=false&storeId=1331275&shopId=20000000000000348\n\n全部授权时为：\nhttps://support.qq.com/products/179257?code=520DD&state=10583&isAllStoreAuth=true&shopId=20000000000000348\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"pcban-ye-mian","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#pcban-ye-mian"}},[_vm._v("¶")]),_vm._v(" pc版页面")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("./images/merchant_authorization/1704775864940.png"),"alt":"1704775864940"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("./images/merchant_authorization/1704776092056.png"),"alt":"1704776092056"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{attrs:{"id":"3.shou-quan-jie-kou","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#3.shou-quan-jie-kou"}},[_vm._v("¶")]),_vm._v(" 3.授权接口")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"3.1-tong-guo-codehuo-qu-accesstokenyu-refreshtoken","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#3.1-tong-guo-codehuo-qu-accesstokenyu-refreshtoken"}},[_vm._v("¶")]),_vm._v(" 3.1 通过Code获取AccessToken与RefreshToken")])
},function (){var _vm=this,_c=_vm._self._c;return _c('blockquote',[_c('p',[_vm._v("商户授权确认时会发送code,通过code换取AccessToken与RefreshToken。并保存RefreshToken，当AccessToken过期后(72小时)，可通过RefreshToken刷新AccessToken。如果RefreshToken丢失，只能发起商户重新授权通过code换取。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h5',{attrs:{"id":"qing-qiu-url","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#qing-qiu-url"}},[_vm._v("¶")]),_vm._v(" 请求url：")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"qing-qiu-fang-shi","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#qing-qiu-fang-shi"}},[_vm._v("¶")]),_vm._v(" 请求方式")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"qian-ming-ji-suan","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#qian-ming-ji-suan"}},[_vm._v("¶")]),_vm._v(" 签名计算")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"/docs/developers_guide"}},[_vm._v("请参考开发指南")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ru-can-shuo-ming","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ru-can-shuo-ming"}},[_vm._v("¶")]),_vm._v(" 入参说明")])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("参数")]),_c('th',[_vm._v("字段描述")]),_c('th',[_vm._v("类型/是否必传")]),_c('th',[_vm._v("说明")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("clientId")]),_c('td',[_vm._v("ss6DzHy9GvHB46Jgo")]),_c('td',[_vm._v("String/YES")]),_c('td',[_vm._v("开发者ID(appKey)")])]),_c('tr',[_c('td',[_vm._v("code")]),_c('td',[_vm._v("juwAxvhYCQwPWegule")]),_c('td',[_vm._v("String/YES")]),_c('td',[_vm._v("授权到跳转页获取的code")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ru-can-shi-li","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ru-can-shi-li"}},[_vm._v("¶")]),_vm._v(" 入参示例")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("{\n    clientId:ss6DzHy9GvHB46Jgo\n    code:sTiuBAIEBRYrfBNEMJ    \n}\n\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"chu-can-shi-li","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#chu-can-shi-li"}},[_vm._v("¶")]),_vm._v(" 出参示例")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{staticClass:"language-json",attrs:{"AAA":"json"}},[_vm._v("{\n \"msg\": \"操作成功\",\n \"code\": 200,\n \"data\": {\n    \"expiresIn\": 259220,\n    \"accessToken\": \"a2b2a198-932f-4628-ba15-d90d9ae94db9\",\n    \"refreshToken\": \"0bfd1b5f-cd85-4d3a-8b87-67aed59244f5\"\n },\n \"status\": \"SUCCESS\"\n }\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"3.2-refreshtokenshua-xin-accesstoken","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#3.2-refreshtokenshua-xin-accesstoken"}},[_vm._v("¶")]),_vm._v(" 3.2 RefreshToken刷新AccessToken")])
},function (){var _vm=this,_c=_vm._self._c;return _c('blockquote',[_c('p',[_vm._v("每个商户授权给服务商之后，会生成AccessToken。服务商通过AccessToken代商户发起操作。AccessToken会过期（72小时），所以服务商开发时应该缓存每个商户的AccessToken，并在过期前通过RefreshToken刷新AccessToken")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"qing-qiu-url-1","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#qing-qiu-url-1"}},[_vm._v("¶")]),_vm._v(" 请求url：")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"qing-qiu-fang-shi-1","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#qing-qiu-fang-shi-1"}},[_vm._v("¶")]),_vm._v(" 请求方式")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"qian-ming-ji-suan-1","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#qian-ming-ji-suan-1"}},[_vm._v("¶")]),_vm._v(" 签名计算")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"/docs/developers_guide"}},[_vm._v("请参考开发指南")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ru-can-shuo-ming-1","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ru-can-shuo-ming-1"}},[_vm._v("¶")]),_vm._v(" 入参说明")])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("参数")]),_c('th',[_vm._v("字段描述")]),_c('th',[_vm._v("类型/是否必传")]),_c('th',[_vm._v("说明")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("clientId")]),_c('td',[_vm._v("ss6DzHy9GvHB46Jgo")]),_c('td',[_vm._v("String/YES")]),_c('td',[_vm._v("开发者ID(appKey)")])]),_c('tr',[_c('td',[_vm._v("sign")]),_c('td',[_vm._v("AD1D5AC34875897302C6726438C5A528")]),_c('td',[_vm._v("String/YES")]),_c('td',[_vm._v("签名")])]),_c('tr',[_c('td',[_vm._v("timestamp")]),_c('td',[_vm._v("1631862240982")]),_c('td',[_vm._v("String/YES")]),_c('td',[_vm._v("时间戳")])]),_c('tr',[_c('td',[_vm._v("data")]),_c('td',[_vm._v("{“refreshToken”:“53521dad-60d2-4bf7-a81e-3882c2721bf1”}")]),_c('td',[_vm._v("String/YES")]),_c('td',[_vm._v("业务参数")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ru-can-shi-li-1","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ru-can-shi-li-1"}},[_vm._v("¶")]),_vm._v(" 入参示例")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("{\n    \"clientId\":\"t8ljLE2mKPwEgAcVr\",\n    \"timestamp\":\"1631862240982\",\n    \"data\":\"{\\\"refreshToken\\\": \\\"0c98ed75-e4d7-48ce-9d51-77a0ec7ad88b\\\"}\",\n    \"sign\":\"82AA9A7AA677CF60A54B31FE6D522B5F\"\n}\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"chu-can-shi-li-1","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#chu-can-shi-li-1"}},[_vm._v("¶")]),_vm._v(" 出参示例")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("{\n    \"msg\": \"操作成功\",\n    \"code\": 200,\n    \"data\": {\n        \"expiresIn\": 259220,\n        \"accessToken\": \"d7180c82-47c8-4350-a15d-054fd4ef0dc4\"\n    },\n    \"status\": \"SUCCESS\"\n}\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"3.3-qu-xiao-shou-quan-jie-kou","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#3.3-qu-xiao-shou-quan-jie-kou"}},[_vm._v("¶")]),_vm._v(" 3.3 取消授权接口")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"qing-qiu-url-2","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#qing-qiu-url-2"}},[_vm._v("¶")]),_vm._v(" 请求url：")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"qing-qiu-fang-shi-2","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#qing-qiu-fang-shi-2"}},[_vm._v("¶")]),_vm._v(" 请求方式")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"qian-ming-ji-suan-2","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#qian-ming-ji-suan-2"}},[_vm._v("¶")]),_vm._v(" 签名计算")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"/guidance/developers_guide"}},[_vm._v("请参考开发指南")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ru-can-shuo-ming-2","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ru-can-shuo-ming-2"}},[_vm._v("¶")]),_vm._v(" 入参说明")])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("参数")]),_c('th',[_vm._v("字段描述")]),_c('th',[_vm._v("类型/是否必传")]),_c('th',[_vm._v("说明")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("clientId")]),_c('td',[_vm._v("ss6DzHy9GvHB46Jgo")]),_c('td',[_vm._v("String/YES")]),_c('td',[_vm._v("开发者ID(appKey)")])]),_c('tr',[_c('td',[_vm._v("sign")]),_c('td',[_vm._v("6355317A7C5047FA13C0ECC7E58B2C72")]),_c('td',[_vm._v("String/YES")]),_c('td',[_vm._v("签名")])]),_c('tr',[_c('td',[_vm._v("timestamp")]),_c('td',[_vm._v("1631862240982")]),_c('td',[_vm._v("String/YES")]),_c('td',[_vm._v("时间戳")])]),_c('tr',[_c('td',[_vm._v("data")]),_c('td',[_vm._v("{“accessToken”:“64850c4c-06fe-46fa-b30a-f680b193fe15”}")]),_c('td',[_vm._v("String/YES")]),_c('td',[_vm._v("业务参数")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ru-can-shi-li-2","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ru-can-shi-li-2"}},[_vm._v("¶")]),_vm._v(" 入参示例")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("{\n    \"clientId\":\"t8ljLE2mKPwEgAcVr\",\n    \"timestamp\":\"1631862240982\",\n    \"data\":\"{\\\"accessToken\\\": \\\"d7180c82-47c8-4350-a15d-054fd4ef0dc4\\\"}\",\n    \"sign\":\"B5DDB48D6DE7AA467FD816BDA51A2177\"\n}\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"chu-can-shi-li-2","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#chu-can-shi-li-2"}},[_vm._v("¶")]),_vm._v(" 出参示例")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("{\n    \"msg\": \"取消授权成功\",\n    \"code\": 200,\n    \"status\": \"SUCCESS\"\n}\n")])])
}]

export { render, staticRenderFns }