var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content element-doc"},[_vm._m(0),_vm._m(1),_c('demo-block',[_c('template',{slot:"source"},[_c('element-demo0',{staticClass:"element-demo0"})],1)],2),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24),_vm._m(25),_vm._m(26),_vm._m(27)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"ding-dan-qu-xiao","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ding-dan-qu-xiao"}},[_vm._v("¶")]),_vm._v(" 订单取消")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("接口说明：\n真正的取消订单。\n注：取件后取消订单，会按照骑手将物品送回的往返里程计算取消费，当取消费大于订单实付金额时，会产生送回费，需用户额外支付；\n此时若deductFlag是否同意扣除余额选择false，即不同意时，会导致取消失败。\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"qing-qiu-url","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#qing-qiu-url"}},[_vm._v("¶")]),_vm._v(" 请求url")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('code',[_vm._v("POST")]),_vm._v(" /open/api/paoOrder/abortOrder")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"qian-ming-ji-suan","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#qian-ming-ji-suan"}},[_vm._v("¶")]),_vm._v(" 签名计算")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"/docs/developers_guide"}},[_vm._v("请参考开发指南")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ce-shi-lian-diao","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ce-shi-lian-diao"}},[_vm._v("¶")]),_vm._v(" 测试联调")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"/docs/testing_and_debugging"}},[_vm._v("联调说明")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ru-can-shuo-ming","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ru-can-shuo-ming"}},[_vm._v("¶")]),_vm._v(" 入参说明")])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("参数")]),_c('th',[_vm._v("示例")]),_c('th',[_vm._v("说明")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("clientId")]),_c('td',[_vm._v("ss6DzHy9GvHB46Jgo")]),_c('td',[_vm._v("App-key")])]),_c('tr',[_c('td',[_vm._v("accessToken")]),_c('td',[_vm._v("70882965-5e51-463f-a746-7bcf1b3f1c34")]),_c('td',[_c('a',{attrs:{"href":"/docs/merchant_authorization"}},[_vm._v("通过商户授权获取")])])]),_c('tr',[_c('td',[_vm._v("timestamp")]),_c('td',[_vm._v("1631862240982")]),_c('td',[_vm._v("毫秒级时间戳")])]),_c('tr',[_c('td',[_vm._v("data")]),_c('td',[_vm._v("参考入参示例")]),_c('td',[_vm._v("业务入参")])]),_c('tr',[_c('td',[_vm._v("sign")]),_c('td',[_vm._v("63068EBC3C28B7F822F74DAB874D4027")]),_c('td',[_vm._v("签名计算参考"),_c('a',{attrs:{"href":"/docs/developers_guide"}},[_vm._v("开发指南")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"datacan-shu-shuo-ming","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#datacan-shu-shuo-ming"}},[_vm._v("¶")]),_vm._v(" data参数说明")])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("参数")]),_c('th',[_vm._v("字段描述")]),_c('th',[_vm._v("类型/是否必传")]),_c('th',[_vm._v("说明")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("orderId")]),_c('td',[_vm._v("订单ID号")]),_c('td',[_vm._v("String(32)/YES")]),_c('td',[_c('a',{attrs:{"href":"/docs/orderPlace"}},[_vm._v("订单提交接口获取")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"chu-can-shuo-ming","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#chu-can-shuo-ming"}},[_vm._v("¶")]),_vm._v(" 出参说明")])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("参数")]),_c('th',[_vm._v("类型/是否必传")]),_c('th',[_vm._v("说明")]),_c('th',[_vm._v("示例")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("sendBackFee")]),_c('td',[_vm._v("Integer(8)/YES")]),_c('td',[_vm._v("送回费，单位：元（取件后取消订单，当取消费大于订单实付金额时，会产生送回费，需用户额外支付）")]),_c('td',[_vm._v("0")])]),_c('tr',[_c('td',[_vm._v("deductAmount")]),_c('td',[_vm._v("Integer(8)/YES")]),_c('td',[_vm._v("扣款金额，单位：元")]),_c('td',[_vm._v("0")])]),_c('tr',[_c('td',[_vm._v("abortType")]),_c('td',[_vm._v("String(2)/YES")]),_c('td',[_vm._v("取消发起人，详见下放枚举")]),_c('td',[_vm._v("'1'")])]),_c('tr',[_c('td',[_vm._v("punishType")]),_c('td',[_vm._v("String(2)/NO")]),_c('td',[_vm._v("取消责任人，详见下放枚举")]),_c('td',[_vm._v("'1'")])]),_c('tr',[_c('td',[_vm._v("abortReason")]),_c('td',[_vm._v("String(20)/YES")]),_c('td',[_vm._v("取消原因")]),_c('td',[_vm._v("客户主动取消订单")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"aborttypemei-ju","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#aborttypemei-ju"}},[_vm._v("¶")]),_vm._v(" abortType枚举")])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("类型")]),_c('th',[_vm._v("描述")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("1")]),_c('td',[_vm._v("客户发起取消")])]),_c('tr',[_c('td',[_vm._v("2")]),_c('td',[_vm._v("商家发起取消")])]),_c('tr',[_c('td',[_vm._v("3")]),_c('td',[_vm._v("骑手发起取消")])]),_c('tr',[_c('td',[_vm._v("10")]),_c('td',[_vm._v("系统自动发起取消")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"punishtypemei-ju","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#punishtypemei-ju"}},[_vm._v("¶")]),_vm._v(" punishType枚举")])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("类型")]),_c('th',[_vm._v("描述")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("1")]),_c('td',[_vm._v("因客户")])]),_c('tr',[_c('td',[_vm._v("2")]),_c('td',[_vm._v("因商家")])]),_c('tr',[_c('td',[_vm._v("3")]),_c('td',[_vm._v("因骑手")])]),_c('tr',[_c('td',[_vm._v("10")]),_c('td',[_vm._v("因系统自动取消")])]),_c('tr',[_c('td',[_vm._v("99")]),_c('td',[_vm._v("因其它")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ding-dan-qu-xiao-liu-cheng-tu","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ding-dan-qu-xiao-liu-cheng-tu"}},[_vm._v("¶")]),_vm._v(" 订单取消流程图")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("./images/abortOrder/1706608160380.png"),"alt":"1706608160380"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ru-can-shi-li-datawei-ye-wu-can-shu-jsonxu-lie-hua-hou-chuan-ru","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ru-can-shi-li-datawei-ye-wu-can-shu-jsonxu-lie-hua-hou-chuan-ru"}},[_vm._v("¶")]),_vm._v(" 入参示例(data为业务参数，Json序列化后传入 )")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("{\n    \"clientId\": \"t8ljLE2mKPwEgAcVr\",\n    \"accessToken\": \"d077bb79-4c8b-46a8-a06e-4a01a98bff6a\",\n    \"timestamp\": \"1631862240982\",\n    \"sign\": \"A1B4871AF792FDF449FE5291D4206D13\",\n    \"data\": \"{\\\"orderId\\\": \\\"1751934464728399874\\\"}\"\n}\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ye-wu-can-shu-shi-li","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ye-wu-can-shu-shi-li"}},[_vm._v("¶")]),_vm._v(" 业务参数示例")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("{\n    \"orderId\": \"1751934464728399874\",\n}\n\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"chu-can-shi-li","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#chu-can-shi-li"}},[_vm._v("¶")]),_vm._v(" 出参示例")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("{\n    \"msg\": \"操作成功\",\n    \"code\": 200,\n    \"data\": {\n        \"punishAmount\": 0,\n        \"sendBackFee\": 0,\n        \"desc\": \"频繁取消订单，可能会产生取消费。若因配送员原因取消，请联系配送员操作取消或投诉配送员\"\n    },\n    \"status\": \"SUCCESS\"\n}\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"jie-kou-zhu-yi-dian","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#jie-kou-zhu-yi-dian"}},[_vm._v("¶")]),_vm._v(" 接口注意点")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("\n\n")])])
}]

export { render, staticRenderFns }