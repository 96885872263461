
    <template>
      <section class="content element-doc">
        <h3 id="ding-dan-zhuang-tai-hui-diao" tabindex="-1"><a class="header-anchor" href="#ding-dan-zhuang-tai-hui-diao">¶</a> 订单状态回调</h3>
<demo-block>
        
        <template slot="source"><element-demo0 class="element-demo0"/></template>
        </demo-block><pre><code AAA="">接口说明：
1：当订单状态发生变化时，会回调账号配置的地址（账户中心-&gt;应用中心-&gt;回调URL：NotifyUrl）来通知订单状态的变化。
2：回调请求方式为POST,请求格式为application/json，编码utf-8。
3：状态（status）有回退的情况，待取货（已就位：30）可能回退到派单中（转单改派中：20）的状态，子状态（subStatus）以后可能会扩展。
4：回调返回的格式必须是{&quot;status&quot;:200,&quot;msg&quot;:&quot;&quot;,&quot;data&quot;:&quot;&quot;}格式，当status为200表示回调成功，否则，回调失败。当回调失败时，间隔一分钟重试一次，最多重试五次。
</code></pre>
<h4 id="hui-diao-can-shu-shuo-ming" tabindex="-1"><a class="header-anchor" href="#hui-diao-can-shu-shuo-ming">¶</a> 回调参数说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>类型/是否必传</th>
<th>字段描述</th>
<th>示例</th>
</tr>
</thead>
<tbody>
<tr>
<td>orderId</td>
<td>String(32)/YES</td>
<td>订单ID号</td>
<td>1640267697197420545</td>
</tr>
<tr>
<td>deliveryNo</td>
<td>String(32)/YES</td>
<td>订单流水号</td>
<td>CG2023041909389053</td>
</tr>
<tr>
<td>orderStatus</td>
<td>String(2)/YES</td>
<td>订单状态，参考下放订单状态枚举</td>
<td>'20'</td>
</tr>
<tr>
<td>orderStatusDesc</td>
<td>String(8)/YES</td>
<td>订单状态描述，参考下放订单状态枚举</td>
<td>派单中</td>
</tr>
<tr>
<td>subOrderStatus</td>
<td>String(2)/NO</td>
<td>订单子状态，参考下放订单状态枚举</td>
<td>'1'</td>
</tr>
<tr>
<td>subOrderStatusDesc</td>
<td>String(8)/NO</td>
<td>订单子状态描述，参考下放订单状态枚举</td>
<td>派单中</td>
</tr>
<tr>
<td>fetchCode</td>
<td>String(8)/NO</td>
<td>取件密码，计费接口中pickupPwd传1时，下单后，订单会生成取件码</td>
<td></td>
</tr>
<tr>
<td>finishCode</td>
<td>String(8)/NO</td>
<td>收件密码，骑手取件后生成</td>
<td></td>
</tr>
<tr>
<td>deductAmount</td>
<td>Integer(8)/NO</td>
<td>扣款金额，单位：元</td>
<td>'0'</td>
</tr>
<tr>
<td>abortType</td>
<td>Integer(8)/NO</td>
<td>取消发起人，详见下放枚举</td>
<td>'1'</td>
</tr>
<tr>
<td>punishType</td>
<td>Integer(8)/NO</td>
<td>取消责任人，详见下放枚举</td>
<td>'1'</td>
</tr>
<tr>
<td>abortReason</td>
<td>String(64)/NO</td>
<td>取消原因</td>
<td>客户主动取消订单</td>
</tr>
<tr>
<td>courierInfo</td>
<td>Object/NO</td>
<td>骑手信息</td>
<td></td>
</tr>
<tr>
<td>sendBackFee</td>
<td>Integer(8)/YES</td>
<td>送回费，单位：元</td>
<td>0</td>
</tr>
<tr>
<td>drawBackFee</td>
<td>Integer(8)/YES</td>
<td>退款金额，单位：元</td>
<td>0</td>
</tr>
</tbody>
</table>
<h4 id="couriershuo-ming" tabindex="-1"><a class="header-anchor" href="#couriershuo-ming">¶</a> courier说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>类型/是否必传</th>
<th>说明</th>
<th>示例</th>
</tr>
</thead>
<tbody>
<tr>
<td>latitude</td>
<td>String(20)/NO</td>
<td>骑手位置纬度（高德坐标系）</td>
<td>40.055091</td>
</tr>
<tr>
<td>longitude</td>
<td>String(20)/NO</td>
<td>骑手位置经度（高德坐标系）</td>
<td>116.289263</td>
</tr>
<tr>
<td>name</td>
<td>String(20)/YES</td>
<td>骑手姓名</td>
<td>韩师傅</td>
</tr>
<tr>
<td>mobile</td>
<td>String(20)/YES</td>
<td>骑手手机号</td>
<td>19000000000</td>
</tr>
<tr>
<td>time</td>
<td>String(24)/YES</td>
<td>骑手位置所处的当前时间</td>
<td>2021-10-15 14:11:44</td>
</tr>
<tr>
<td>type</td>
<td>Integer(8)/NO</td>
<td>字段弃用，可忽略</td>
<td>1</td>
</tr>
<tr>
<td>orderCount</td>
<td>Integer(8)/NO</td>
<td>骑手服务总次数</td>
<td>4</td>
</tr>
<tr>
<td>headIcon</td>
<td>String(100)/NO</td>
<td>骑手头像</td>
<td></td>
</tr>
<tr>
<td>id</td>
<td>String(20)/NO</td>
<td>骑手id</td>
<td>SSBJ000000001</td>
</tr>
<tr>
<td>blacklisted</td>
<td>Integer(8)/NO</td>
<td>字段弃用，可忽略</td>
<td>0</td>
</tr>
<tr>
<td>deliveryProcessTrail</td>
<td>Object[]/NO</td>
<td>配送过程轨迹列表</td>
<td></td>
</tr>
<tr>
<td>estimateDeliveryTimeTip</td>
<td>String(100)/NO</td>
<td>预计送达时间文案,骑手未接单或者订单终态，该值为空</td>
<td>227米,预计1分钟上门</td>
</tr>
</tbody>
</table>
<h4 id="deliveryprocesstrailshuo-ming" tabindex="-1"><a class="header-anchor" href="#deliveryprocesstrailshuo-ming">¶</a> deliveryProcessTrail说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>类型/是否必传</th>
<th>说明</th>
<th>示例</th>
</tr>
</thead>
<tbody>
<tr>
<td>longitude</td>
<td>String(20)/YES</td>
<td>骑手位置经度（百度坐标系）</td>
<td>116.289263</td>
</tr>
<tr>
<td>latitude</td>
<td>String(20)/YES</td>
<td>骑手位置纬度（百度坐标系）</td>
<td>40.055091</td>
</tr>
<tr>
<td>datetime</td>
<td>String(24)/YES</td>
<td>骑手位置所处的当前时间</td>
<td>2021-10-14 13:30:31</td>
</tr>
</tbody>
</table>
<h4 id="ding-dan-zhuang-tai-mei-ju" tabindex="-1"><a class="header-anchor" href="#ding-dan-zhuang-tai-mei-ju">¶</a> 订单状态枚举</h4>
<table>
<thead>
<tr>
<th>status：订单状态</th>
<th>statusDesc：状态描述</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>20</td>
<td>派单中（转单改派中）</td>
<td>商户已经支付，订单分配给配送员（subStatus为1，表示派单中；subStatus为2，表示转单改派中）</td>
</tr>
<tr>
<td>30</td>
<td>待取货（已就位）</td>
<td>配送员已经接单（subStatus为1，表示待取货；subStatus为2，表示已就位）</td>
</tr>
<tr>
<td>40</td>
<td>闪送中（申请取消中、物品送回中、取消单客服介入中）</td>
<td>已经取件，配送中（subStatus为1，表示闪送中；subStatus为2，申请取消中；subStatus为3，物品送回中；subStatus为4，取消单客服介入中）</td>
</tr>
<tr>
<td>50</td>
<td>已完成（已退款）</td>
<td>已经配送完成（subStatus为1：已完成；subStatus为2：已退款；）</td>
</tr>
<tr>
<td>60</td>
<td>已取消</td>
<td>已经取消订单</td>
</tr>
</tbody>
</table>
<h4 id="aborttypemei-ju" tabindex="-1"><a class="header-anchor" href="#aborttypemei-ju">¶</a> abortType枚举</h4>
<table>
<thead>
<tr>
<th>类型</th>
<th>描述</th>
</tr>
</thead>
<tbody>
<tr>
<td>1</td>
<td>客户发起取消</td>
</tr>
<tr>
<td>2</td>
<td>商家发起</td>
</tr>
<tr>
<td>3</td>
<td>骑手发起取消</td>
</tr>
<tr>
<td>10</td>
<td>系统自动发起取消</td>
</tr>
</tbody>
</table>
<h4 id="punishtypemei-ju" tabindex="-1"><a class="header-anchor" href="#punishtypemei-ju">¶</a> punishType枚举</h4>
<table>
<thead>
<tr>
<th>类型</th>
<th>描述</th>
</tr>
</thead>
<tbody>
<tr>
<td>1</td>
<td>因客户</td>
</tr>
<tr>
<td>2</td>
<td>因商家</td>
</tr>
<tr>
<td>3</td>
<td>因骑手</td>
</tr>
<tr>
<td>10</td>
<td>因系统自动取消</td>
</tr>
<tr>
<td>99</td>
<td>因其它</td>
</tr>
</tbody>
</table>
<h4 id="pai-dan-zhong-can-shu-shi-li" tabindex="-1"><a class="header-anchor" href="#pai-dan-zhong-can-shu-shi-li">¶</a> 派单中参数示例</h4>
<pre><code AAA="">{
    &quot;orderId&quot;:&quot;TDH2021101514763986&quot;,
    &quot;deliveryNo&quot;:&quot;C1119A000013053981&quot;,
    &quot;status&quot;:20,
    &quot;statusDesc&quot;:&quot;派单中&quot;,
    &quot;subStatus&quot;:1,
    &quot;subStatusDesc&quot;:&quot;派单中&quot;,
    &quot;drawback&quot;:0,
    &quot;sendBackFee&quot;:0
}
</code></pre>
<h4 id="dai-qu-huo-can-shu-shi-li" tabindex="-1"><a class="header-anchor" href="#dai-qu-huo-can-shu-shi-li">¶</a> 待取货参数示例</h4>
<pre><code AAA="">{
    &quot;orderId&quot;:&quot;TDH2021101514763986&quot;,
    &quot;deliveryNo&quot;:&quot;C1119A000013053981&quot;,
    &quot;status&quot;:30,
    &quot;statusDesc&quot;:&quot;待取货&quot;,
    &quot;subStatus&quot;:1,
    &quot;subStatusDesc&quot;:&quot;待取货&quot;,
    &quot;drawback&quot;:0,
    &quot;sendBackFee&quot;:0,
    &quot;deliveryPassword&quot;:&quot;&quot;,
    &quot;pickupPassword&quot;:&quot;&quot;,
    &quot;courier&quot;:{
        &quot;latitude&quot;:&quot;40.055091&quot;,
        &quot;longitude&quot;:&quot;116.289263&quot;,
        &quot;name&quot;:&quot;韩师傅&quot;,
        &quot;mobile&quot;:&quot;19000000000&quot;,
        &quot;time&quot;:&quot;2021-10-15 14:11:44&quot;,
        &quot;type&quot;:1,
        &quot;orderCount&quot;:4,
        &quot;headIcon&quot;:&quot;&quot;,
        &quot;id&quot;:&quot;SSBJ000000001&quot;,
        &quot;blacklisted&quot;:0,
        &quot;deliveryProcessTrail&quot;:[
            {
                &quot;datetime&quot;:&quot;2021-10-15 12:11:44&quot;,
                &quot;latitude&quot;:40.066191,
                &quot;longitude&quot;:116.289993
            },
            {
                &quot;datetime&quot;:&quot;2021-10-15 13:11:44&quot;,
                &quot;latitude&quot;:40.067591,
                &quot;longitude&quot;:116.297363
            },
            {
                &quot;datetime&quot;:&quot;2021-10-15 14:11:44&quot;,
                &quot;latitude&quot;:40.155091,
                &quot;longitude&quot;:116.399263
            }
        ],
        &quot;estimateDeliveryTimeTip&quot;:&quot;227米,预计1分钟上门&quot;
    }
}
    
</code></pre>
<h4 id="qu-xiao-can-shu-shi-li" tabindex="-1"><a class="header-anchor" href="#qu-xiao-can-shu-shi-li">¶</a> 取消参数示例</h4>
<pre><code AAA="">{
    &quot;orderId&quot;:&quot;TDH2021101514763986&quot;,
    &quot;deliveryNo&quot;:&quot;C1119A000013053981&quot;,
    &quot;status&quot;:60,
    &quot;statusDesc&quot;:&quot;已取消&quot;,
    &quot;deductAmount&quot;:0,
    &quot;drawback&quot;:0,
    &quot;sendBackFee&quot;:0,
    &quot;abortType&quot;:1,
    &quot;punishType&quot;:1,
    &quot;abortReason&quot;:&quot;客户主动取消订单&quot;
}
</code></pre>

      </section>
    </template>
    <script>
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div')}
var staticRenderFns = []

  
    const democomponentExport = {}
    return {
      render,
      staticRenderFns,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
   <style></style>
  