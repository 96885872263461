
    <template>
      <section class="content element-doc">
        <h3 id="ding-dan-ji-fei" tabindex="-1"><a class="header-anchor" href="#ding-dan-ji-fei">¶</a> 订单计费</h3>
<pre><code AAA="">接口说明：
1、用来进行计费使用，可以理解为下单询价。需要注意的是计费和真正支付的金额可能存在不一样。
2、测试环境的计费规则是随意配置的，一切订单计价的费用以线上为准，理论上测试环境只要能够计费成功就可以。
</code></pre>
<demo-block>
        
        <template slot="source"><element-demo0 class="element-demo0"/></template>
        </demo-block><h4 id="qing-qiu-url" tabindex="-1"><a class="header-anchor" href="#qing-qiu-url">¶</a> 请求url</h4>
<p><code>POST</code>  /open/api/paoOrder/orderCalculate</p>
<h4 id="qian-ming-ji-suan" tabindex="-1"><a class="header-anchor" href="#qian-ming-ji-suan">¶</a> 签名计算</h4>
<p><a href="/docs/developers_guide">请参考开发指南</a></p>
<h4 id="ce-shi-lian-diao" tabindex="-1"><a class="header-anchor" href="#ce-shi-lian-diao">¶</a> 测试联调</h4>
<p><a href="/docs/testing_and_debugging">联调说明</a></p>
<h4 id="ru-can-shuo-ming" tabindex="-1"><a class="header-anchor" href="#ru-can-shuo-ming">¶</a> 入参说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>示例</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>clientId</td>
<td>ss6DzHy9GvHB46Jgo</td>
<td>App-key</td>
</tr>
<tr>
<td>accessToken</td>
<td>70882965-5e51-463f-a746-7bcf1b3f1c34</td>
<td><a href="/docs/merchant_authorization">通过商户授权获取</a></td>
</tr>
<tr>
<td>timestamp</td>
<td>1631862240982</td>
<td>毫秒级时间戳</td>
</tr>
<tr>
<td>data</td>
<td>参考入参示例</td>
<td>业务入参</td>
</tr>
<tr>
<td>sign</td>
<td>63068EBC3C28B7F822F74DAB874D4027</td>
<td>签名计算参考<a href="/docs/developers_guide">开发指南</a></td>
</tr>
</tbody>
</table>
<h4 id="datacan-shu-shuo-ming" tabindex="-1"><a class="header-anchor" href="#datacan-shu-shuo-ming">¶</a> data参数说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>字段描述</th>
<th>类型/是否必传</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>city</td>
<td>城市名称</td>
<td>String(32)/YES</td>
<td><a href="/docs/openCitiesLists">查询开通城市接口获取</a>，如果确定自己对接的城市区域开通闪送服务，并且知道城市名称就不需要查询开通城市接口</td>
</tr>
<tr>
<td>district</td>
<td>区域名称</td>
<td>String(32)/YES</td>
<td><a href="/docs/openCitiesLists">查询开通城市接口获取</a>，如果确定自己对接的城市区域开通闪送服务，并且知道城市名称就不需要查询开通城市接口</td>
</tr>
<tr>
<td>storeId</td>
<td>店铺ID</td>
<td>String(32)/YES</td>
<td><a href="/docs/storePage">查询商户店铺</a>获取的storeId字段</td>
</tr>
<tr>
<td>cargoNum</td>
<td>物品数量</td>
<td>Integer(8)/YES</td>
<td>物品数量，一般填1</td>
</tr>
<tr>
<td>cargoType</td>
<td>物品类型</td>
<td>Integer(8)/YES</td>
<td>详见下方物品类型标签枚举</td>
</tr>
<tr>
<td>cargoWeight</td>
<td>物品重量</td>
<td>double(16,2)/YES</td>
<td>单位为kg；最大重量不超过50kg，默认5kg,不需传单位。</td>
</tr>
<tr>
<td>cargoLength</td>
<td>物品长度</td>
<td>double(16,2)/YES</td>
<td>单位为cm；默认20cm,不需传单位。</td>
</tr>
<tr>
<td>cargoWidth</td>
<td>物品宽度</td>
<td>double(16,2)/YES</td>
<td>单位为cm；默认20cm,不需传单位。</td>
</tr>
<tr>
<td>cargoHeight</td>
<td>物品高度</td>
<td>double(16,2)/YES</td>
<td>单位为cm；默认20cm,不需传单位。</td>
</tr>
<tr>
<td>fromAddress</td>
<td>寄件地址</td>
<td>String(100)/YES</td>
<td>通过查询店铺信息取得寄件人地址与坐标信息。所以新建店铺时必须完善相关信息</td>
</tr>
<tr>
<td>fromAddressDetail</td>
<td>寄件详细地址</td>
<td>String(150)/YES</td>
<td></td>
</tr>
<tr>
<td>fromName</td>
<td>寄件人姓名</td>
<td>String(30)/YES</td>
<td></td>
</tr>
<tr>
<td>fromPhone</td>
<td>寄件人电话</td>
<td>String(30)/YES</td>
<td>支持11位手机号;支持座机号(格式：010-12345678 或者010-12345678-123,英文中划线分隔);支持400电话（格式：4006126688）</td>
</tr>
<tr>
<td>fromLat</td>
<td>寄件纬度</td>
<td>double(16,6)/YES</td>
<td>发件地址以该纬度为准，GCJ-02坐标系（高德地图、腾讯地图）</td>
</tr>
<tr>
<td>fromLng</td>
<td>寄件经度</td>
<td>double(16,6)/YES</td>
<td>发件地址以该经度为准，GCJ-02坐标系（高德地图、腾讯地图）</td>
</tr>
<tr>
<td>receiverAddress</td>
<td>收件地址</td>
<td>String(100)/YES</td>
<td></td>
</tr>
<tr>
<td>receiverAddressDetail</td>
<td>收件详细地址</td>
<td>String(150)/YES</td>
<td></td>
</tr>
<tr>
<td>receiverName</td>
<td>收件人姓名</td>
<td>String(30)/YES</td>
<td></td>
</tr>
<tr>
<td>receiverPhone</td>
<td>收件人电话</td>
<td>String(30)/YES</td>
<td>支持11位手机号;支持座机号(格式：010-12345678 或者010-12345678-123,英文中划线分隔);支持400电话（格式：4006126688）</td>
</tr>
<tr>
<td>receiverLat</td>
<td>收件纬度</td>
<td>double(16,6)/YES</td>
<td>收件地址以该纬度为准，GCJ-02坐标系（高德地图、腾讯地图）</td>
</tr>
<tr>
<td>receiverLng</td>
<td>收件经度</td>
<td>double(16,6)/YES</td>
<td>收件地址以该经度为准，GCJ-02坐标系（高德地图、腾讯地图）</td>
</tr>
<tr>
<td>orderingSourceType</td>
<td>订单来源</td>
<td>String(8)/NO</td>
<td>详见下方订单来源枚举</td>
</tr>
<tr>
<td>orderingSourceNo</td>
<td>订单来源流水号</td>
<td>String(32)/NO</td>
<td>订单来源流水号</td>
</tr>
<tr>
<td>deliveryType</td>
<td>帮我取 帮我送</td>
<td>String(2)/NO</td>
<td>'0':帮我取 '1':帮我送,默认'1'</td>
</tr>
<tr>
<td>qualityDelivery</td>
<td>是否专送</td>
<td>String(2)/NO</td>
<td>是否专送（0：不是；1：是）,默认'0''</td>
</tr>
<tr>
<td>pickupDisturb</td>
<td>取件勿扰</td>
<td>String(2)/NO</td>
<td>取件勿扰,默认'0'不开启</td>
</tr>
<tr>
<td>fetchValid</td>
<td>取件验证</td>
<td>String(2)/NO</td>
<td>取件验证,默认'0'不开启</td>
</tr>
<tr>
<td>finishValidb</td>
<td>收件验证</td>
<td>String(2)/NO</td>
<td>收件验证,默认'0'不开启</td>
</tr>
<tr>
<td>appointType</td>
<td>预约类型</td>
<td>String(2)/NO</td>
<td>0立即单，1预约单，默认'0'立即单</td>
</tr>
<tr>
<td>appointmentDate</td>
<td>预约时间</td>
<td>String(32)/NO</td>
<td>yyyy-MM-dd HH:mm格式（例如：2020-02-02 22:00）,指的是预约取件时间,只支持一个小时以后两天以内</td>
</tr>
</tbody>
</table>
<h4 id="chu-can-shuo-ming" tabindex="-1"><a class="header-anchor" href="#chu-can-shuo-ming">¶</a> 出参说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>类型/是否必传</th>
<th>说明</th>
<th>示例</th>
</tr>
</thead>
<tbody>
<tr>
<td>totalDistance</td>
<td>Integer(8)/YES</td>
<td>总距离，单位：米</td>
<td>1600</td>
</tr>
<tr>
<td>totalWeight</td>
<td>Integer(8)/YES</td>
<td>总重量，单位：kg</td>
<td>2</td>
</tr>
<tr>
<td>orderNumber</td>
<td>String(20)/YES</td>
<td>闪送订单号（有效期30分钟）</td>
<td>TDH2021110113343411</td>
</tr>
<tr>
<td>feeInfoList</td>
<td>Object[]/YES</td>
<td>费用明细</td>
<td></td>
</tr>
<tr>
<td>totalAmount</td>
<td>Integer(8)/YES</td>
<td>订单总金额，未优惠需要支付的费用，单位：分</td>
<td>4621</td>
</tr>
<tr>
<td>couponSaveFee</td>
<td>Integer(8)/YES</td>
<td>优惠的总额度，单位：分</td>
<td>0</td>
</tr>
<tr>
<td>dynamicSubsidyAmount</td>
<td>Integer(8)/YES</td>
<td>限时补贴的费用，单位：分；字段弃用</td>
<td>0</td>
</tr>
<tr>
<td>totalFeeAfterSave</td>
<td>Integer(8)/YES</td>
<td>实际支付的费用，单位：分</td>
<td>4621</td>
</tr>
<tr>
<td>expectReceiveTime</td>
<td>String(8)/NO</td>
<td>字段弃用</td>
<td></td>
</tr>
<tr>
<td>estimateGrabSecond</td>
<td>Integer(8)/YES</td>
<td>预计接单时长，字段弃用，如需获取该时间，可调用：查询订单ETA 接口</td>
<td>213</td>
</tr>
<tr>
<td>estimateReceiveSecond</td>
<td>Integer(8)/YES</td>
<td>预计完单时长，字段弃用，如需获取该时间，可调用：查询订单ETA 接口</td>
<td>828</td>
</tr>
<tr>
<td>interestDtoList</td>
<td>Object[]/YES</td>
<td>服务费用明细</td>
<td></td>
</tr>
</tbody>
</table>
<h4 id="wu-pin-lei-xing-biao-qian-mei-ju-cargotype" tabindex="-1"><a class="header-anchor" href="#wu-pin-lei-xing-biao-qian-mei-ju-cargotype">¶</a> 物品类型标签枚举（cargoType）</h4>
<table>
<thead>
<tr>
<th>物品类型标签</th>
<th>物品类型标签名称</th>
</tr>
</thead>
<tbody>
<tr>
<td>1</td>
<td>文件</td>
</tr>
<tr>
<td>2</td>
<td>证件</td>
</tr>
<tr>
<td>3</td>
<td>数码</td>
</tr>
<tr>
<td>5</td>
<td>蛋糕</td>
</tr>
<tr>
<td>6</td>
<td>餐饮</td>
</tr>
<tr>
<td>7</td>
<td>鲜花</td>
</tr>
<tr>
<td>8</td>
<td>海鲜</td>
</tr>
<tr>
<td>9</td>
<td>水果</td>
</tr>
<tr>
<td>10</td>
<td>药品</td>
</tr>
<tr>
<td>11</td>
<td>服饰</td>
</tr>
<tr>
<td>14</td>
<td>汽配</td>
</tr>
<tr>
<td>15</td>
<td>其他</td>
</tr>
</tbody>
</table>
<h4 id="ding-dan-lai-yuan-mei-ju-orderingsourcetype" tabindex="-1"><a class="header-anchor" href="#ding-dan-lai-yuan-mei-ju-orderingsourcetype">¶</a> 订单来源枚举（orderingSourceType）</h4>
<table>
<thead>
<tr>
<th>订单来源标签</th>
<th>订单来源名称</th>
</tr>
</thead>
<tbody>
<tr>
<td>0</td>
<td>驰鸽</td>
</tr>
<tr>
<td>1</td>
<td>闪送</td>
</tr>
<tr>
<td>2</td>
<td>百度外卖</td>
</tr>
<tr>
<td>3</td>
<td>饿了么</td>
</tr>
<tr>
<td>4</td>
<td>美团外卖</td>
</tr>
<tr>
<td>5</td>
<td>其他平台</td>
</tr>
<tr>
<td>6</td>
<td>京东到家</td>
</tr>
<tr>
<td>7</td>
<td>达达</td>
</tr>
<tr>
<td>8</td>
<td>饿百</td>
</tr>
</tbody>
</table>
<h4 id="ru-can-shi-li-datawei-ye-wu-can-shu-jsonxu-lie-hua-hou-chuan-ru" tabindex="-1"><a class="header-anchor" href="#ru-can-shi-li-datawei-ye-wu-can-shu-jsonxu-lie-hua-hou-chuan-ru">¶</a> 入参示例(data为业务参数，Json序列化后传入 )</h4>
<pre><code AAA="">{
    &quot;clientId&quot;: &quot;t8ljLE2mKPwEgAcVr&quot;,
    &quot;accessToken&quot;: &quot;70882965-5e51-463f-a746-7bcf1b3f1c34&quot;,
    &quot;timestamp&quot;: &quot;1631862240982&quot;,
    &quot;sign&quot;: &quot;F439B21AA8A1EC320C5690694180FFD0&quot;,
    &quot;data&quot;: &quot;{ \&quot;cargoPrice\&quot;: 0,    \&quot;cargoNum\&quot;: 1,    \&quot;fromName\&quot;: \&quot;张三\&quot;,    \&quot;fromPhone\&quot;: \&quot;15846200000\&quot;,    \&quot;fromAddress\&quot;: \&quot;深圳市洁普科技有限公司\&quot;,    \&quot;fromAddressDetail\&quot;: \&quot;广东省深圳市宝安区新安街道翻身49区锦驰商务A座512\&quot;,    \&quot;fromLat\&quot;: 22.553329,    \&quot;fromLng\&quot;: 113.883068,    \&quot;city\&quot;: \&quot;深圳市\&quot;,    \&quot;district\&quot;: \&quot;宝安区\&quot;,    \&quot;receiverName\&quot;: \&quot;李四\&quot;,    \&quot;receiverPhone\&quot;: \&quot;15866699999\&quot;,    \&quot;receiverAddress\&quot;: \&quot;深圳市宝安区纪检委\&quot;,    \&quot;receiverAddressDetail\&quot;: \&quot;广东省深圳市宝安区创业一路1号区委区政府\&quot;,    \&quot;receiverLat\&quot;: 22.553596,    \&quot;receiverLng\&quot;: 113.883549,    \&quot;cargoType\&quot;: 1,    \&quot;cargoHeight\&quot;: 20,    \&quot;cargoLength\&quot;: 25,    \&quot;cargoWeight\&quot;: 5,    \&quot;cargoWidth\&quot;: 25}&quot;
}
</code></pre>
<h4 id="ye-wu-can-shu-shi-li" tabindex="-1"><a class="header-anchor" href="#ye-wu-can-shu-shi-li">¶</a> 业务参数示例</h4>
<pre><code AAA="">{
    &quot;storeId&quot;: &quot;1654324366655098882&quot;,
    &quot;cargoPrice&quot;: 0,
    &quot;cargoNum&quot;: 1,
    &quot;fromName&quot;: &quot;张三&quot;,
    &quot;fromPhone&quot;: &quot;15846200000&quot;,
    &quot;fromAddress&quot;: &quot;深圳市洁普科技有限公司&quot;,
    &quot;fromAddressDetail&quot;: &quot;广东省深圳市宝安区新安街道翻身49区锦驰商务A座512&quot;,
    &quot;fromLat&quot;: 22.553329,
    &quot;fromLng&quot;: 113.883068,
    &quot;city&quot;: &quot;深圳市&quot;,
    &quot;district&quot;: &quot;宝安区&quot;,
    &quot;receiverName&quot;: &quot;李四&quot;,
    &quot;receiverPhone&quot;: &quot;15866699999&quot;,
    &quot;receiverAddress&quot;: &quot;深圳市宝安区纪检委&quot;,
    &quot;receiverAddressDetail&quot;: &quot;广东省深圳市宝安区创业一路1号区委区政府&quot;,
    &quot;receiverLat&quot;: 22.553596,
    &quot;receiverLng&quot;: 113.883549,
    &quot;cargoType&quot;: 1,
    &quot;cargoHeight&quot;: 20,
    &quot;cargoLength&quot;: 25,
    &quot;cargoWeight&quot;: 5,
    &quot;cargoWidth&quot;: 25
}

</code></pre>
<h4 id="chu-can-shi-li" tabindex="-1"><a class="header-anchor" href="#chu-can-shi-li">¶</a> 出参示例</h4>
<pre><code AAA="">{
    &quot;msg&quot;: &quot;操作成功&quot;,
    &quot;result&quot;: &quot;OK&quot;,
    &quot;code&quot;: 200,
    &quot;data&quot;: {
        &quot;storeId&quot;: &quot;1654324366655098882&quot;,
        &quot;city&quot;: &quot;深圳市&quot;,
        &quot;district&quot;: &quot;宝安区&quot;,
        &quot;deliveryNo&quot;: &quot;CG2024012815434091&quot;,
        &quot;deptId&quot;: 105,
        &quot;fromName&quot;: &quot;骆生&quot;,
        &quot;fromPhone&quot;: &quot;19520700000&quot;,
        &quot;fetchValid&quot;: &quot;0&quot;,
        &quot;finishValid&quot;: &quot;0&quot;,
        &quot;fromAddress&quot;: &quot;测试&quot;,
        &quot;fromAddressDetail&quot;: &quot;广东省深圳市宝安区新安街道翻身49区锦驰商务A座512&quot;,
        &quot;qualityDelivery&quot;: &quot;0&quot;,
        &quot;fromLat&quot;: 22.553329,
        &quot;fromLng&quot;: 113.883068,
        &quot;receiverName&quot;: &quot;张三&quot;,
        &quot;receiverPhone&quot;: &quot;19520700000&quot;,
        &quot;finishCode&quot;: null,
        &quot;receiverAddress&quot;: &quot;香谷园商店&quot;,
        &quot;receiverAddressDetail&quot;: &quot;广东省深圳市宝安区23区大宝路风彩轩8号&quot;,
        &quot;appointmentDate&quot;: null,
        &quot;appointType&quot;: null,
        &quot;receiverLat&quot;: 22.575537,
        &quot;receiverLng&quot;: 113.915766,
        &quot;cargoPrice&quot;: 0,
        &quot;cargoType&quot;: &quot;1&quot;,
        &quot;cargoNum&quot;: &quot;1&quot;,
        &quot;cargoWeight&quot;: 5,
        &quot;cargoHeight&quot;: 20,
        &quot;cargoLength&quot;: 25,
        &quot;cargoWidth&quot;: 25,
        &quot;pickUpPos&quot;: null,
        &quot;fetchCode&quot;: null,
        &quot;cargoDetail&quot;: null,
        &quot;orderingSourceType&quot;: null,
        &quot;orderingSourceTypeDesc&quot;: null,
        &quot;orderingSourceNo&quot;: null,
        &quot;courierPickupOrderPhotos&quot;: null,
        &quot;courierDeliveryOrderPhotos&quot;: null,
        &quot;orderStatus&quot;: null,
        &quot;subOrderStatus&quot;: null,
        &quot;payType&quot;: null,
        &quot;totalFeeAfterSave&quot;: 7.71,
        &quot;totalAmount&quot;: 7.71,
        &quot;insuranceFee&quot;: 0,
        &quot;couponSaveFee&quot;: 0,
        &quot;interestDtoList&quot;: [
            {
                &quot;type&quot;: &quot;1&quot;,
                &quot;desc&quot;: &quot;里程费&quot;,
                &quot;fee&quot;: 7.71
            }
        ],
        &quot;sendBackFee&quot;: null,
        &quot;fromDistance&quot;: null,
        &quot;totalDistance&quot;: 5.14,
        &quot;estimateGrabSecond&quot;: 480,
        &quot;estimateReceiveSecond&quot;: 1200,
        &quot;orderStatusDesc&quot;: null,
        &quot;subOrderStatusDesc&quot;: null,
        &quot;cargoTypeDesc&quot;: &quot;文件&quot;,
        &quot;payTypeDesc&quot;: null,
        &quot;outTime&quot;: null,
        &quot;receivingCountdown&quot;: null,
        &quot;predictDeliverySecond&quot;: 2280,
        &quot;predictDeliveryTime&quot;: null,
        &quot;polyline&quot;: [,,,],
        &quot;paoSplitLogList&quot;: null,
        &quot;diningOutTime&quot;: 0,
        &quot;beginTime&quot;: null,
        &quot;endTime&quot;: null,
        &quot;findUserCount&quot;: 0,
        &quot;delFlag&quot;: null
    },
    &quot;status&quot;: &quot;SUCCESS&quot;
}
</code></pre>
<h4 id="jie-kou-zhu-yi-dian" tabindex="-1"><a class="header-anchor" href="#jie-kou-zhu-yi-dian">¶</a> 接口注意点</h4>
<pre><code AAA="">1、经纬度是否在正确的范围内；
2、测试环境的城市计费不准确，请切换到深圳市宝安区测试，不影响线上使用；可能是正式环境该城市没有开通闪送服务，请去开放平台文档中查询开城城市；

</code></pre>

      </section>
    </template>
    <script>
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div')}
var staticRenderFns = []

  
    const democomponentExport = {}
    return {
      render,
      staticRenderFns,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
   <style></style>
  