<template>
  <div id="app">
    <div class="wrapper">
      <div class="main-content">
        <TopBar class="my-topbar"/>
        <div class="flex-row justify-content-center">
          <router-view style="width: 1280px;"></router-view> <!--根据路由更换的组件放在了router-view中，实现页面的跳转-->
        </div>
      </div>
    </div>

    <Footer class="myfooter" />

  </div>
</template>

<script>
import TopBar from '@/components/TopBar'
import Footer from '@/components/Footer'
import store from '@/store';
export default {
  name: 'App',
  components: {
    TopBar, Footer
  },
  mounted() {
    let w = document.documentElement.clientWidth;
    store.commit('device', w < 992 ? 'mobile' : 'desktop')
    window.onresize = function temp() {
      // that.clientHeight = `${document.documentElement.clientHeight}px`;
      console.log(document.documentElement.clientWidth)
      w = document.documentElement.clientWidth;
      store.commit('device', w < 992 ? 'mobile' : 'desktop')
    };
  }
}
</script>

<style>
body {
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, Arial, sans-serif;
}

.wrapper {
  min-height: calc(100% - 50px);
  /* 100px 是 Footer 的高度 */
  /* margin-bottom: 50px; */
  /* 为了避免 Footer 遮挡内容 */
}

.main-content {
  padding-bottom: 50px;
  /* 100px 是 Footer 的高度 */
}

.myfooter {
  display: -webkit-flex;
    /* Safari */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  height: 50px;

  /* Footer 的高度 */
}</style>
