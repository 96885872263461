
    <template>
      <section class="content element-doc">
        <h3 id="ti-jiao-ding-dan" tabindex="-1"><a class="header-anchor" href="#ti-jiao-ding-dan">¶</a> 提交订单</h3>
<pre><code AAA="">接口说明：
1、订单下单时会调用商户账户支付，如果商户余额不足，需要到小程序端自主充值。
2、测试环境的计费规则是随意配置的，一切提交订单的费用以线上为准，理论上测试环境只要能够提交订单成功就可以。
</code></pre>
<demo-block>
        
        <template slot="source"><element-demo0 class="element-demo0"/></template>
        </demo-block><h4 id="qing-qiu-url" tabindex="-1"><a class="header-anchor" href="#qing-qiu-url">¶</a> 请求url</h4>
<p><code>POST</code>  /open/api/paoOrder/orderPlace</p>
<h4 id="qian-ming-ji-suan" tabindex="-1"><a class="header-anchor" href="#qian-ming-ji-suan">¶</a> 签名计算</h4>
<p><a href="/docs/developers_guide">请参考开发指南</a></p>
<h4 id="ce-shi-lian-diao" tabindex="-1"><a class="header-anchor" href="#ce-shi-lian-diao">¶</a> 测试联调</h4>
<p><a href="/docs/testing_and_debugging">联调说明</a></p>
<h4 id="ru-can-shuo-ming" tabindex="-1"><a class="header-anchor" href="#ru-can-shuo-ming">¶</a> 入参说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>示例</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>clientId</td>
<td>ss6DzHy9GvHB46Jgo</td>
<td>App-key</td>
</tr>
<tr>
<td>accessToken</td>
<td>70882965-5e51-463f-a746-7bcf1b3f1c34</td>
<td><a href="/docs/merchant_authorization">通过商户授权获取</a></td>
</tr>
<tr>
<td>timestamp</td>
<td>1631862240982</td>
<td>毫秒级时间戳</td>
</tr>
<tr>
<td>data</td>
<td>参考入参示例</td>
<td>业务入参</td>
</tr>
<tr>
<td>sign</td>
<td>63068EBC3C28B7F822F74DAB874D4027</td>
<td>签名计算参考<a href="/docs/developers_guide">开发指南</a></td>
</tr>
</tbody>
</table>
<h4 id="datacan-shu-shuo-ming" tabindex="-1"><a class="header-anchor" href="#datacan-shu-shuo-ming">¶</a> data参数说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>字段描述</th>
<th>类型/是否必传</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>deliveryNo</td>
<td>订单流水号</td>
<td>String(32)/YES</td>
<td><a href="/docs/orderCalculate">订单计费</a>接口获取</td>
</tr>
</tbody>
</table>
<h4 id="chu-can-shuo-ming" tabindex="-1"><a class="header-anchor" href="#chu-can-shuo-ming">¶</a> 出参说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>类型/是否必传</th>
<th>说明</th>
<th>示例</th>
</tr>
</thead>
<tbody>
<tr>
<td>orderId</td>
<td>String(32)/YES</td>
<td>订单ID号</td>
<td></td>
</tr>
<tr>
<td>deliveryNo</td>
<td>String(32)/YES</td>
<td>订单序列号</td>
<td></td>
</tr>
<tr>
<td>orderStatus</td>
<td>String(2)/YES</td>
<td>订单状态</td>
<td></td>
</tr>
<tr>
<td>cargoType</td>
<td>物品类型</td>
<td>Integer(8)/YES</td>
<td>详见下方物品类型标签枚举</td>
</tr>
<tr>
<td>cargoTypeDesc</td>
<td>物品类型描述</td>
<td>Integer(8)/YES</td>
<td>详见下方物品类型标签枚举</td>
</tr>
<tr>
<td>subOrderStatus</td>
<td>String(2)/YES</td>
<td>订单子状态</td>
<td></td>
</tr>
<tr>
<td>orderStatusDesc</td>
<td>String(8)/YES</td>
<td>订单状态描述</td>
<td></td>
</tr>
<tr>
<td>subOrderStatusDesc</td>
<td>String(8)/YES</td>
<td>订单子状态描述</td>
<td></td>
</tr>
<tr>
<td>payType</td>
<td>String(2)/YES</td>
<td>支付类型</td>
<td></td>
</tr>
<tr>
<td>payTypeDesc</td>
<td>String(32)/YES</td>
<td>支付类型描述</td>
<td></td>
</tr>
<tr>
<td>totalAmount</td>
<td>double(16,2)/YES</td>
<td>订单总费用</td>
<td>订单总费用</td>
</tr>
<tr>
<td>totalFeeAfterSave</td>
<td>double(16,2)/YES</td>
<td>订单支付费用</td>
<td>订单支付的费用（总费用-优惠）</td>
</tr>
<tr>
<td>insuranceFee</td>
<td>double(16,2)/YES</td>
<td>保费</td>
<td></td>
</tr>
<tr>
<td>couponSaveFee</td>
<td>double(16,2)/YES</td>
<td>优惠额</td>
<td></td>
</tr>
<tr>
<td>sendBackFee</td>
<td>double(16,2)/YES</td>
<td>送回费用</td>
<td>订单取消后，如果需要送回，则会有送回费用</td>
</tr>
<tr>
<td>interestDtoList</td>
<td>Object[]/YES</td>
<td>订单费用明细</td>
<td></td>
</tr>
<tr>
<td>fromDistance</td>
<td>Integer(8)/YES</td>
<td>骑手接单后，骑手接单距离</td>
<td></td>
</tr>
<tr>
<td>totalDistance</td>
<td>Integer(8)/YES</td>
<td>总里程</td>
<td></td>
</tr>
<tr>
<td>outTime</td>
<td>Integer(8)/YES</td>
<td>合计时，不同状态下的当前状态合计时</td>
<td></td>
</tr>
<tr>
<td>orderingSourceType</td>
<td>String(8)/NO</td>
<td>订单来源</td>
<td>详见下方订单来源枚举</td>
</tr>
<tr>
<td>orderingSourceTypeDesc</td>
<td>String(8)/NO</td>
<td>订单来源描述</td>
<td>详见下方订单来源枚举</td>
</tr>
<tr>
<td>orderingSourceNo</td>
<td>String(32)/NO</td>
<td>订单来源流水号</td>
<td>订单来源流水号</td>
</tr>
<tr>
<td>estimateGrabSecond</td>
<td>Integer(8)/NO</td>
<td>预计接单时长（分）</td>
<td></td>
</tr>
<tr>
<td>estimateReceiveSecond</td>
<td>Integer(8)/NO</td>
<td>预计完单时长 （分）</td>
<td></td>
</tr>
<tr>
<td>receivingCountdown</td>
<td>Integer(8)/NO</td>
<td>接单过期时间 （分）</td>
<td></td>
</tr>
<tr>
<td>predictDeliverySecond</td>
<td>Integer(8)/NO</td>
<td>订单预计送达时长 （分）</td>
<td></td>
</tr>
<tr>
<td>polyline</td>
<td>double[]/NO</td>
<td>配送路径</td>
<td></td>
</tr>
<tr>
<td>diningOutTime</td>
<td>Integer(8)/NO</td>
<td>出餐倒计时（秒）</td>
<td></td>
</tr>
<tr>
<td>beginTime</td>
<td>DateTime(8)/NO</td>
<td>订单开始时间</td>
<td></td>
</tr>
<tr>
<td>endTime</td>
<td>DateTime(8)/NO</td>
<td>订单结果时间</td>
<td></td>
</tr>
<tr>
<td>endTime</td>
<td>DateTime(8)/NO</td>
<td>订单结果时间</td>
<td></td>
</tr>
<tr>
<td>findUserCount</td>
<td>Integer(8)/NO</td>
<td>附近可用骑手数量</td>
<td></td>
</tr>
</tbody>
</table>
<h4 id="ding-dan-zhuang-tai-shuo-ming" tabindex="-1"><a class="header-anchor" href="#ding-dan-zhuang-tai-shuo-ming">¶</a> 订单状态说明</h4>
<table>
<thead>
<tr>
<th>订单状态码</th>
<th>订单状态</th>
<th>订单状态说明</th>
<th>子状态</th>
</tr>
</thead>
<tbody>
<tr>
<td>0</td>
<td>查询单</td>
<td></td>
<td></td>
</tr>
<tr>
<td>10</td>
<td>待支付</td>
<td></td>
<td></td>
</tr>
<tr>
<td>20</td>
<td>派单中（转单改派中）</td>
<td>商户已经支付，订单分配给配送员</td>
<td>1 等待派单,2 已匹配骑手；3，转单改派中</td>
</tr>
<tr>
<td>30</td>
<td>待取货（已就位）</td>
<td>配送员已经接单</td>
<td></td>
</tr>
<tr>
<td>40</td>
<td>配送中</td>
<td>申请取消中、物品送回中、取消单客服介入中. 已经取件，配送中</td>
<td>1:闪送中；2:申请取消中；3:物品送回中；4:取消单客服介入中</td>
</tr>
<tr>
<td>50</td>
<td>已完成（已退款）</td>
<td>已经配送完成</td>
<td>1：已完成；subStatus为2：已退款；</td>
</tr>
<tr>
<td>60</td>
<td>已取消 已经取消订单</td>
<td></td>
<td></td>
</tr>
</tbody>
</table>
<h4 id="ru-can-shi-li-datawei-ye-wu-can-shu-jsonxu-lie-hua-hou-chuan-ru" tabindex="-1"><a class="header-anchor" href="#ru-can-shi-li-datawei-ye-wu-can-shu-jsonxu-lie-hua-hou-chuan-ru">¶</a> 入参示例(data为业务参数，Json序列化后传入 )</h4>
<pre><code AAA="">{
    &quot;clientId&quot;: &quot;t8ljLE2mKPwEgAcVr&quot;,
    &quot;accessToken&quot;: &quot;70882965-5e51-463f-a746-7bcf1b3f1c34&quot;,
    &quot;timestamp&quot;: &quot;1631862240982&quot;,
    &quot;sign&quot;: &quot;F439B21AA8A1EC320C5690694180FFD0&quot;,
    &quot;data&quot;: &quot;{\&quot;deliveryNo\&quot;: \&quot;CG2023041909389053\&quot;}&quot;
}
</code></pre>
<h4 id="ye-wu-can-shu-shi-li" tabindex="-1"><a class="header-anchor" href="#ye-wu-can-shu-shi-li">¶</a> 业务参数示例</h4>
<pre><code AAA="">{
    &quot;deliveryNo&quot;: &quot;CG2023041909389053&quot;
}

</code></pre>
<h4 id="chu-can-shi-li" tabindex="-1"><a class="header-anchor" href="#chu-can-shi-li">¶</a> 出参示例</h4>
<pre><code AAA="">{
	&quot;msg&quot;: &quot;操作成功&quot;,
	&quot;result&quot;: &quot;OK&quot;,
	&quot;code&quot;: 200,
	&quot;data&quot;: {
		&quot;searchValue&quot;: null,
		&quot;createBy&quot;: null,
		&quot;createTime&quot;: null,
		&quot;updateBy&quot;: null,
		&quot;updateTime&quot;: null,
		&quot;remark&quot;: null,
		&quot;params&quot;: {},
		&quot;orderId&quot;: &quot;1751547452843888641&quot;,
		&quot;shopId&quot;: &quot;1654324366655098882&quot;,
		&quot;city&quot;: &quot;深圳市&quot;,
		&quot;district&quot;: &quot;宝安区&quot;,
		&quot;localCode&quot;: &quot;深圳市宝安区&quot;,
		&quot;deliveryNo&quot;: &quot;CG2024012818036477&quot;,
		&quot;deliveryNoPlus&quot;: null,
		&quot;deliveryNoBack&quot;: null,
		&quot;userId&quot;: &quot;1617518469018877953&quot;,
		&quot;deptId&quot;: 105,
		&quot;userInfo&quot;: null,
		&quot;courierId&quot;: null,
		&quot;courierInfo&quot;: null,
		&quot;deliveryType&quot;: null,
		&quot;lbsType&quot;: null,
		&quot;fromName&quot;: &quot;李生&quot;,
		&quot;pickupDisturb&quot;: null,
		&quot;fromPhone&quot;: &quot;19520700000&quot;,
		&quot;fetchValid&quot;: null,
		&quot;finishValid&quot;: null,
		&quot;fromAddress&quot;: &quot;测试&quot;,
		&quot;fromAddressDetail&quot;: &quot;广东省深圳市宝安区新安街道翻身49区锦驰商务A座512&quot;,
		&quot;qualityDelivery&quot;: null,
		&quot;fromLat&quot;: 22.553329,
		&quot;fromLng&quot;: 113.883068,
		&quot;receiverName&quot;: &quot;张三&quot;,
		&quot;receiverPhone&quot;: &quot;19520700000&quot;,
		&quot;finishCode&quot;: null,
		&quot;receiverAddress&quot;: &quot;香谷园商店&quot;,
		&quot;receiverAddressDetail&quot;: &quot;广东省深圳市宝安区23区大宝路风彩轩8号&quot;,
		&quot;appointmentDate&quot;: null,
		&quot;appointType&quot;: null,
		&quot;receiverLat&quot;: 22.575537,
		&quot;receiverLng&quot;: 113.915766,
		&quot;cargoPrice&quot;: 0,
		&quot;cargoType&quot;: &quot;1&quot;,
		&quot;cargoNum&quot;: &quot;1&quot;,
		&quot;cargoWeight&quot;: 5,
		&quot;cargoHeight&quot;: 20,
		&quot;cargoLength&quot;: 25,
		&quot;cargoWidth&quot;: 25,
		&quot;pickUpPos&quot;: null,
		&quot;fetchCode&quot;: null,
		&quot;cargoDetail&quot;: null,
		&quot;orderingSourceType&quot;: null,
		&quot;orderingSourceTypeDesc&quot;: null,
		&quot;orderingSourceNo&quot;: null,
		&quot;courierPickupOrderPhotos&quot;: null,
		&quot;courierDeliveryOrderPhotos&quot;: null,
		&quot;orderStatus&quot;: &quot;10&quot;,
		&quot;subOrderStatus&quot;: null,
		&quot;payType&quot;: null,
		&quot;totalFeeAfterSave&quot;: 7.71,
		&quot;totalAmount&quot;: 7.71,
		&quot;insuranceFee&quot;: 0,
		&quot;couponSaveFee&quot;: 0.0,
		&quot;interestDtoList&quot;: [{
			&quot;type&quot;: &quot;1&quot;,
			&quot;desc&quot;: &quot;里程费&quot;,
			&quot;fee&quot;: 7.71
		}],
		&quot;sendBackFee&quot;: null,
		&quot;fromDistance&quot;: null,
		&quot;totalDistance&quot;: 5.14,
		&quot;estimateGrabSecond&quot;: 300.0,
		&quot;estimateReceiveSecond&quot;: 1200.0,
		&quot;orderStatusDesc&quot;: &quot;待支付&quot;,
		&quot;subOrderStatusDesc&quot;: null,
		&quot;cargoTypeDesc&quot;: &quot;文件&quot;,
		&quot;payTypeDesc&quot;: null,
		&quot;outTime&quot;: null,
		&quot;receivingCountdown&quot;: null,
		&quot;predictDeliverySecond&quot;: 2100.0,
		&quot;predictDeliveryTime&quot;: null,
		&quot;polyline&quot;: [...],
		&quot;paoSplitLogList&quot;: null,
		&quot;diningOutTime&quot;: 0,
		&quot;beginTime&quot;: null,
		&quot;endTime&quot;: null,
		&quot;findUserCount&quot;: 0,
		&quot;delFlag&quot;: null
	},
	&quot;status&quot;: &quot;SUCCESS&quot;
}
</code></pre>
<h4 id="jie-kou-zhu-yi-dian" tabindex="-1"><a class="header-anchor" href="#jie-kou-zhu-yi-dian">¶</a> 接口注意点</h4>
<pre><code AAA="">

</code></pre>

      </section>
    </template>
    <script>
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div')}
var staticRenderFns = []

  
    const democomponentExport = {}
    return {
      render,
      staticRenderFns,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
   <style></style>
  