import Vue from "vue"; //引入vue js是一个vue的配置文件 vue版本是2.6
import Router from "vue-router"; //vue-router 安装的对象 npm install vue-router@"3.0.1"
import VueRouter from "vue-router";
import Guide from "@/components/md_docs/Guide.vue";
// import User from "../views/user"
//vue中使用Router
Vue.use(Router);
const routers = [
  //配置router与组件之间的对应关系
  {
    path: "/",
    component: () => import("@/views/index"),
    name: "Index",
  },
  { path: "/docs/:name", component: Guide },
  {
    path: "/auth",
    component: () => import("@/views/auth"),
    name: "Auth",
  },
  {
    path: "/login",
    component: () => import("@/views/login/login"),
    name: "Login",
  },
  {
    path: "/regProgress",
    component: () => import("@/views/thirdParty/regProgress"),
    name: "RegProgress",
    meta: { title: "progressTitle" },
  },
  {
    path: "/",
    component: () => import("@/layout/index"),
    redirect: "/baseInfo",
    hidden: true,
    children: [
      {
        path: "baseInfo",
        component: () => import("@/views/thirdParty/baseInfo"),
        name: "BaseInfo",
        meta: { title: "基本信息", icon: "dashboard", affix: true },
      },
      {
        path: "acountInfo",
        component: () => import("@/views/thirdParty/acountInfo"),
        name: "AcountInfo",
        meta: { title: "应用信息", icon: "dashboard", affix: true },
      },
      {
        path: "simulate",
        component: () => import("@/views/thirdParty/simulate"),
        name: "Simulate",
        meta: { title: "模拟订单流转", icon: "dashboard", affix: true },
      },      
    ],
  },
];
//创建一个vue-router实例对象
const router = new VueRouter({
  mode: "history", //history 不带#号 hash 带#号
  routes: routers, //所有的路由数组
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
//导出路由对象  使用路由的地方引入即可  一般在入口main.js中使用
export default router;
