var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content element-doc"},[_vm._m(0),_c('demo-block',[_c('template',{slot:"source"},[_c('element-demo0',{staticClass:"element-demo0"})],1)],2),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"cha-xun-kai-tong-cheng-shi","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#cha-xun-kai-tong-cheng-shi"}},[_vm._v("¶")]),_vm._v(" 查询开通城市")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("接口说明：可以用来判断城市是否开通配送服务，供订单计费使用。开通城市基本不变，建议存储到本地并且定时查询进行更新。\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"qing-qiu-url","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#qing-qiu-url"}},[_vm._v("¶")]),_vm._v(" 请求url")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('code',[_vm._v("GET")]),_vm._v(" /open/api/openCitiesLists")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"jie-kou-xie-yi-yi-ji-qian-ming-ji-suan","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#jie-kou-xie-yi-yi-ji-qian-ming-ji-suan"}},[_vm._v("¶")]),_vm._v(" 接口协议以及签名计算")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"/docs/merchant_authorization"}},[_vm._v("请参考开发指南")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ce-shi-lian-diao","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ce-shi-lian-diao"}},[_vm._v("¶")]),_vm._v(" 测试联调")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"/docs/testing_and_debugging"}},[_vm._v("联调说明")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ru-can-shuo-ming","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ru-can-shuo-ming"}},[_vm._v("¶")]),_vm._v(" 入参说明")])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("参数")]),_c('th',[_vm._v("示例")]),_c('th',[_vm._v("说明")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("clientId")]),_c('td',[_vm._v("ss6DzHy9GvHB46Jgo")]),_c('td',[_vm._v("App-key")])]),_c('tr',[_c('td',[_vm._v("accessToken")]),_c('td',[_vm._v("70882965-5e51-463f-a746-7bcf1b3f1c34")]),_c('td',[_c('a',{attrs:{"href":"/docs/merchant_authorization"}},[_vm._v("通过商户授权获取")])])]),_c('tr',[_c('td',[_vm._v("timestamp")]),_c('td',[_vm._v("1631862240982")]),_c('td',[_vm._v("毫秒级时间戳")])]),_c('tr',[_c('td',[_vm._v("sign")]),_c('td',[_vm._v("955E0C0DDAF87B9D26E0171BEF65C0D8")]),_c('td',[_vm._v("签名")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"chu-can-shuo-ming","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#chu-can-shuo-ming"}},[_vm._v("¶")]),_vm._v(" 出参说明")])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("参数")]),_c('th',[_vm._v("类型/是否必传")]),_c('th',[_vm._v("说明")]),_c('th',[_vm._v("示例")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("index")]),_c('td',[_vm._v("String(1)/YES")]),_c('td',[_vm._v("城市首字母")]),_c('td',[_vm._v("A")])]),_c('tr',[_c('td',[_vm._v("name")]),_c('td',[_vm._v("String(20)/YES")]),_c('td',[_vm._v("城市名称")]),_c('td',[_vm._v("鞍山市")])]),_c('tr',[_c('td',[_vm._v("id")]),_c('td',[_vm._v("Long(20)/YES")]),_c('td',[_vm._v("城市ID")]),_c('td',[_vm._v("2130")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ru-can-shi-li","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ru-can-shi-li"}},[_vm._v("¶")]),_vm._v(" 入参示例")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("{\n    clientId:ss6DzHy9GvHB46Jgo\n    accessToken:70882965-5e51-463f-a746-7bcf1b3f1c34\n    timestamp:1631862240982\n    sign:955E0C0DDAF87B9D26E0171BEF65C0D8    \n}\n\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"chu-can-shi-li","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#chu-can-shi-li"}},[_vm._v("¶")]),_vm._v(" 出参示例")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("{\n    \"msg\": \"操作成功\",\n    \"code\": 200,\n    \"data\": [\n        {\n            \"id\": \"1659198396512079874\",\n            \"provinceName\": \"广东省\",\n            \"cityName\": \"江门市\",\n            \"districtName\": \"开平市\"\n        },\n        {\n            \"id\": \"1637395650766249985\",\n            \"provinceName\": \"广东省\",\n            \"cityName\": \"深圳市\",\n            \"districtName\": \"南山区\"\n        },\n        {\n            \"id\": \"1666108306462085122\",\n            \"provinceName\": \"广东省\",\n            \"cityName\": \"深圳市\",\n            \"districtName\": \"宝安区\"\n        },\n        {\n            \"id\": \"1637414875496980481\",\n            \"provinceName\": \"广东省\",\n            \"cityName\": \"深圳市\",\n            \"districtName\": \"福田区\"\n        },\n        {\n            \"id\": \"1711001478988042241\",\n            \"provinceName\": \"广东省\",\n            \"cityName\": \"阳江市\",\n            \"districtName\": \"江城区\"\n        },\n        {\n            \"id\": \"1637414359614365698\",\n            \"provinceName\": \"广东省\",\n            \"cityName\": \"阳江市\",\n            \"districtName\": \"阳西县\"\n        }\n    ],\n    \"status\": \"SUCCESS\"\n}\n")])])
}]

export { render, staticRenderFns }