<template>
  <div>
    <!-- 菜单 -->
    <div class="flex-row justify-content-center border-bottom-gray " style="padding-bottom:6px">
      <div class="flex-row justify-content-between align-items-center fs18" style="width: 1280px;">
        <el-menu v-if="!IsMmobile" :default-active="activeIndex" class="bold" mode="horizontal" style="border-bottom:none" @select="handleSelect">
          <el-menu-item index="1" style="font-size: 16px;" @click="toIndex()">首页</el-menu-item>
          <el-menu-item index="3" style="font-size: 16px;" @click="toCountCenter()" v-if="providerUser">帐户中心</el-menu-item>
          <el-menu-item index="4" style="font-size: 16px;" @click="toDocCenter()">开放文档</el-menu-item>
        </el-menu>
        <i v-else class=" el-icon-s-unfold" @click="drawerShow=true"> </i>

        <div v-if="providerUser" class="flex-row padding-right align-items-center">
          <!-- 头像 -->
          <el-avatar :size="40" :src="providerUser.avatar"></el-avatar>

          <el-dropdown class="padding6" :hide-on-click="false" @command="handleCommand">
            <span class="el-dropdown-link">{{ providerUser.phone }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div v-else class="flex-row padding-right align-items-center">
          <div class="padding-12">
            <el-button type="text" style="font-size: 16px;" @click="clientDialogVisible=true">商户入驻</el-button>
          </div>
          <div class="padding-12">
            <el-button class="padding-12" type="text" style="font-size: 16px;" @click="regDialogVisible=true">平台入驻</el-button>
          </div>
          <div class="padding-12">
            <el-button class="padding-12" type="primary" plain round size="medium" style="width: 100px;" @click="loginDialogVisible=true">登录</el-button>
          </div>
        </div>
      </div>
    </div>

    <el-drawer title="菜单" :visible.sync="drawerShow" direction="ltr" :show-close="false">
      <el-menu :default-active="activeIndex" class="bold" mode="vertical" style="border-bottom:none" @select="handleSelect">
        <el-menu-item index="1" style="font-size: 16px;" @click="toIndex()">首页</el-menu-item>
        <el-menu-item index="3" style="font-size: 16px;" @click="toCountCenter()">帐户中心</el-menu-item>
        <el-menu-item index="4" style="font-size: 16px;" @click="toDocCenter()">开放文档</el-menu-item>
      </el-menu>
    </el-drawer>

    <el-dialog title="商户入驻须知" :visible.sync="clientDialogVisible" center width="800px">
      <div>
        <span>商户您好，您选择的模式为商户类型，请您前往微信搜索【驰鸽配送】小程序注册账号，填写资料。在小程序端可以下单、进行管理订单、账号充值等各种功能。</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="clientDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="平台服务商账号注册" :visible.sync="regDialogVisible" width="500px" center :close-on-click-modal="false">
      <div>
        <el-form :model="reg" :rules="rules" ref="regForm">
          <el-form-item prop="phone">
            <el-input v-model="reg.phone" placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="reg.password" placeholder="请设置您的登录密码" show-password></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="reg.password2" placeholder="请重复输入您的密码" show-password></el-input>
          </el-form-item>
          <!-- <el-form-item prop="code">
            <el-input v-model="reg.code" placeholder="请输入验证码">
              <el-button slot="append" type="success" :disabled="count>0" @click.prevent="getRegisterVerifyCode()">{{count==0?"获取验证码":count+"秒后重新获取"}}</el-button>
            </el-input>

          </el-form-item> -->
          <el-form-item prop="checked">
            <el-checkbox v-model="reg.checked">我已阅读并同意 <el-link type="primary" disabled>《合作协议》</el-link></el-checkbox>
            <div>未注册手机号验证后自动创建账号</div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="regDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="regSubmitForm()">注册</el-button>
      </span>
    </el-dialog>
    <el-dialog title="服务商登录" :visible.sync="loginDialogVisible" width="500px" center :close-on-click-modal="false">
      <div>
        <el-form :model="loginFrom" :rules="rules" ref="loginFrom">
          <el-form-item prop="phone">
            <el-input v-model="loginFrom.phone" placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item v-if="loginByCode==false" prop="password">
            <el-input v-model="loginFrom.password" placeholder="请输入您的登录密码" show-password></el-input>
          </el-form-item>
          <el-form-item v-else prop="code">
            <el-input v-model="loginFrom.code" placeholder="请输入验证码">
              <el-button slot="append" type="success" :disabled="count>0" @click.prevent="getLoginCode()">{{count==0?"获取验证码":count+"秒后重新获取"}}</el-button>
            </el-input>
          </el-form-item>
        </el-form>

        <div class="text-right">
          <!-- <el-link type="primary" @click="loginByCode=!loginByCode">{{ loginByCode?"密码登录":"验证码登录" }}</el-link> -->
        </div>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="loginDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="login()">登录</el-button>
      </span>
    </el-dialog>
  </div>

</template>
  <script>
import store from '@/store';
import { passWordEncrypt } from '@/utils/jsencrypt';
export default {
  data() {
    const validatePass = (rule, value, callback) => {
      //必须包含⼤⼩写字母、数字、特殊字符长度再9-16位之间
      var regex = new RegExp("(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,20}");
      if (value === "") {
        callback(new Error("请设置您的登录密码"));
      } else if (value.length < 8 || value.length > 20) {
        callback(new Error("请输⼊8~20位密码"));
      } else if (!regex.test(value)) {
        callback(new Error("密码必须同时包含字母、数字和特殊字符"));
      } else {
        callback();
      }
    }
    const validatePass2 = (rule, value, callback) => {
     if (value === '') {
       callback(new Error('请再次输入密码'));
     } else if (value !== this.reg.password) {
       callback(new Error('两次输入密码不一致!'));
     } else {
       callback();
     }
    }
    return {
      drawerShow: false,
      regDialogVisible: false,
      loginDialogVisible: false,
      clientDialogVisible: false,
      reg: {
        phone: '',
        code: '',
        password: '',
        checked: false
      },
      loginFrom: {
        phone: '',
        code: '',
        password: ''
      },
      loginByCode: false,
      count: 0,
      timer: null,
      rules: {
        phone: [
          { required: true, message: '请输入合法的手机号码', trigger: 'blur' },
          // { type: 'number',min: 11, max: 11, message: '请输入长度11位数字', trigger: 'blur' }
          { type: 'string', len: 11, message: '请输入长度11位数字', trigger: 'blur' }
        ],
        password: [
          { required: true, validator: validatePass, trigger: 'blur' },
        ],
        password2: [
          { required: true, validator: validatePass2, trigger: 'blur' },
        ],
        code: [
          { required: true, type: 'string', len: 6, message: '请输入长度6位字符', trigger: 'blur' }
        ],

      }
    };
  },
  computed: {
    providerUser: {
      get() {
        return store.getters.providerUser;
      },
      set(val) {
        store.state.providerUser = val;
      }
    },
    activeIndex: {
      get() {
        return store.getters.topBarIndex;
      },
      set(val) {
        store.state.topBarIndex = val;
      }
    },
  },
  methods: {
    handleCommand(command) {
      if (command == "logout") {
        this.logout()
      }
    },
    handleSelect(key, keyPath) {
      this.drawerShow = false

      console.log(key, keyPath);
      store.commit('topBarIndex', key);
      // console.log("2.ProviderUser", this.ProviderUser)
      // console.log("2.providerUser", this.providerUser)
      // console.log('isMmobile', store.state.device);


    },
    getRegisterVerifyCode() {

      var rex = /^1[3-9]\d{9}$/;// /^1[3-9]+\d{9}$/;
      if (rex.test(this.reg.phone)) {
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000)
        }
        this.$http.post("/web/api/providerUser/getRegisterVerifyCode", this.filterForm(this.reg)).then(res => {
          this.$message({
            message: '短信发送成功',
            type: 'success'
          })
        })
      } else {
        this.$message({
          message: '请输入正确的手机号',
          type: 'error'
        })
      }
    },
    getLoginCode() {

      var rex = /^1[3-9]\d{9}$/;// /^1[3-9]+\d{9}$/;
      if (rex.test(this.loginFrom.phone)) {
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000)
        }

        this.$http.post("/web/api/providerUser/getLoginCode", this.filterForm(this.loginFrom)).then(res => {
          this.$message({
            message: '短信发送成功',
            type: 'success'
          })
        })
      } else {
        this.$message({
          message: '请输入正确的手机号',
          type: 'error'
        })
      }
    },
    regSubmitForm() {
      if (!this.reg.checked) {
        this.$message({
          message: '请阅读并同意《合作协议》',
          type: 'error'
        })
        return false;
      }
      this.$refs['regForm'].validate((valid) => {
        if (valid) {
          const data = {
            phone: this.reg.phone,
            code: this.reg.code,
            password: passWordEncrypt(this.reg.password)
          }
          this.$http.post('/web/api/providerUser/register', data).then(res => {
            this.$message.success("注册成功")
            store.commit("login", res.data)
            this.regDialogVisible = false
            this.$router.push({ path: '/regProgress' })
          })
        } else {
          this.$message({
            message: '请按要求填写表单',
            type: 'error'
          })
          return false;
        }
      });
    },
    logout() {
      console.log("logout")
      store.commit('logout')
      this.$router.push({ path: '/' })
    },
    login() {
      this.$refs['loginFrom'].validate((valid) => {
        console.log("valid:", valid)
        if (valid) {
          const url = this.loginByCode ? '/web/api/providerUser/login' : '/web/api/providerUser/login';
          const data = {
            phone: this.loginFrom.phone,
            code: this.loginFrom.code,
            password: passWordEncrypt(this.loginFrom.password)
          }
          this.$http.post(url, this.filterForm(data)).then(res => {
            store.commit("login", res.data)
            this.loginDialogVisible = false
          })
        }
      })
    },
    toIndex() {
      this.$router.push({ path: '/' })
    },
    toCountCenter2() {
      this.$router.push({ path: '/regProgress' })
    },
    toDocCenter(){
      this.$router.push({ path: '/docs/guidance' })
    },
    toCountCenter() {
      this.$router.push({ path: '/baseInfo' })
    },
  }
}
  </script>
<style scoped>
.customWidth {
  width: 500px;
}
</style>

