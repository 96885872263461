import Vue from "vue";
import Vuex from "vuex"; //引入vuex
import Helper from "@/utils/helper";
Vue.use(Vuex); //使用vuex

// //设置localStroage值
// localStorage.setItem('username','zhangsan')
// //获取localStroage值
// localStorage.getItem('username')

const ACCESSTOKEN = Helper.localStorageGetItem("accessToken");
const USERINFO = localStorage.getItem("userInfo") || {};
const PROVIDERUSER = Helper.localStorageGetItem("providerUser");
const MERCHANTUSER = Helper.localStorageGetItem("merchantUser");
export default new Vuex.Store({
  state: {
    // 用户token
    topBarIndex: null,
    sideBarIndex: "1",
    accessToken: ACCESSTOKEN,
    userInfo: USERINFO,
    providerUser: PROVIDERUSER,
    merchantUser: MERCHANTUSER,
    device: 'desktop',
  },
  getters: {
    topBarIndex: (state) => {
      return state.topBarIndex;
    },
    sideBarIndex: (state) => {
      return state.sideBarIndex;
    },
    userInfo: (state) => {
      return state.userInfo;
    },
    providerUser: (state) => {
      return state.providerUser;
    },
    merchantUser: (state) => {
      return state.merchantUser;
    },
    accessToken: (state) => {
      return state.accessToken;
    },
    logout(state) {
      state.accessToken = null;
      state.providerUser =null;
      state.userInfo = null;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("merchantUser");      
      localStorage.removeItem("providerUser");
    },
  },
  mutations: {
    topBarIndex(state, provider) {
      state.topBarIndex = provider;
    },
    sideBarIndex(state, provider) {
      state.sideBarIndex = provider;
    },
    userInfo(state, provider) {
      state.userInfo = provider;
      // state.permissions = provider.permissions;
      // state.roles = provider.roles;
    },
    login(state, provider) {
      state.providerUser = provider.providerUser;
      state.accessToken = provider.accessToken;
      Helper.localStorageSetItem("accessToken",provider.accessToken, provider.expireTime ? provider.expireTime - 1 : 60) ;
      Helper.localStorageSetItem("providerUser",provider.providerUser, provider.expireTime ? provider.expireTime - 1 : 60) ;
    },
    merchantLogin(state, provider) {
      state.merchantUser = provider.merchantUser;
      state.accessToken = provider.accessToken;
      Helper.localStorageSetItem("accessToken",provider.accessToken, provider.expireTime ? provider.expireTime - 1 : 60) ;
      Helper.localStorageSetItem("merchantUser",provider.providerUser, provider.expireTime ? provider.expireTime - 1 : 60) ;
    },
    logout(state) {
      state.accessToken = null;
      state.providerUser = null;
      state.userInfo = null;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("providerUser");
    },
    device: (state, provider) => {
      state.device = provider
    },
  },
  actions: {  },
  modules: {},
});

// 1、state: 存放数据
// 使用:this.$store.state.属性名

// 2、getters: vuex的计算属性, 依赖state中的一个值或多个值产生一个新值, 依赖值发生变化, getters中的方法返回值也会变化
// 使用: this.$getters.方法名 注意不用带括号

// 3、mutations: 规定修改vuex中的数据的唯一途径
// 使用: this.$store.commit(‘方法名’, 实参)

// 4、actions: 用于做一些异步操作的，但是它要修改state数据还得通过mutations
// 使用: this.$store.dispatch(‘方法名’)
