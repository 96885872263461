const CONFIG = {
    // 开发环境配置
    development: {
        assetsPath: '/static', // 静态资源路径
        baseUrl: 'http://localhost:7500', // 后台接口请求地址
        baseApi: 'http://localhost:7500',
        hostUrl: 'http://localhost:7500', // H5地址(前端运行地址)
        websocketUrl: '', // websocket服务端地址
        weixinAppId: '' // 微信公众号appid
    },
    // 生产环境配置
    production: {
        assetsPath: '/static', // 静态资源路径
        baseUrl: 'https://api.gdcgps.cn', // 后台接口请求地址
        baseApi: 'https://api.gdcgps.cn',
        hostUrl: 'https://api.gdcgps.cn', // H5地址(前端运行地址)
        websocketUrl: '', // websocket服务端地址
        weixinAppId: '' // 微信公众号appid
    }



};
export default CONFIG[process.env.NODE_ENV];