export default [
  // {
  //   name: "更新日志",
  //   path: "/changelog",
  // },
  // {
  //   name: "Iconfont",
  //   href: "https://www.iconfont.cn/",
  // },

  {
    name: "对接流程",
    children: [
      {
        path: "/guidance",
        name: "接入引导",
      },
      {
        path: "/testing_and_debugging",
        name: "测试联调说明",
      },
      {
        path: "/developers_guide",
        name: "开发指南",
      },   
      {
        path: "/merchant_authorization",
        name: "商户授权",
      },     
      {
        path: "/notifyOrderStatus",
        name: "订单状态回调",
      },       
      // {
      //   path: "/docs-rule",
      //   name: "常见问题",
      // },                 
    ],
  },
  {
    name: "接口文档",
    groups: [
      {
        groupName: "订单相关",
        list: [
          {
            path: "/openCitiesLists",
            title: "查询开通城市",
          },
          {
            path: "/orderCalculate",
            title: "订单计费",
          },   
          {
            path: "/orderPlace",
            title: "提交订单",
          },   
          {
            path: "/orderInfo",
            title: "查询订单详情",
          },        
          {
            path: "/orderPage",
            title: "查询商户订单列表",
          },      
          {
            path: "/preAbortOrder",
            title: "订单预取消",
          },     
          {
            path: "/abortOrder",
            title: "订单取消",
          },     
          {
            path: "/confirmGoodsReturn",
            title: "确认物品送回",
          },               
          {
            path: "/updateToMobile",
            title: "修改收件人手机号",
          },   
        ],
      },
      {
        groupName: "店铺相关",
        list: [
          {
            path: "/storeOperation",
            title: "店铺管理",
          },          
          {
            path: "/storePage",
            title: "分页查询商户店铺",
          },      
        ],
      },

    ],

  },
];
