
    <template>
      <section class="content element-doc">
        <h3 id="dian-pu-guan-li" tabindex="-1"><a class="header-anchor" href="#dian-pu-guan-li">¶</a> 店铺管理</h3>
<pre><code AAA="">接口说明：
1、请商家到小程序开通商家服务以及增加店铺/修改店铺，充值。开放平台不提供店铺管理相关接口。
</code></pre>
<demo-block>
        
        <template slot="source"><element-demo0 class="element-demo0"/></template>
        </demo-block>
      </section>
    </template>
    <script>
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div')}
var staticRenderFns = []

  
    const democomponentExport = {}
    return {
      render,
      staticRenderFns,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
   <style></style>
  