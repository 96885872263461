
    <template>
      <section class="content element-doc">
        <h1>开发指南</h1>
<demo-block>
        
        <template slot="source"><element-demo0 class="element-demo0"/></template>
        </demo-block><h3 id="yi-qing-qiu-xie-yi-shuo-ming" tabindex="-1"><a class="header-anchor" href="#yi-qing-qiu-xie-yi-shuo-ming">¶</a> 一：请求协议说明：</h3>
<p>调用流程说明</p>
<p>1.合作方调用配送开放平台API，需要按照以下步骤：</p>
<p>2.填充参数&gt;生成签名&gt;拼装HTTP请求&gt;发起HTTP请求&gt;得到HTTP响应&gt;解析json</p>
<h3 id="er-jie-kou-xie-yi" tabindex="-1"><a class="header-anchor" href="#er-jie-kou-xie-yi">¶</a> 二：接口协议</h3>
<table>
<thead>
<tr>
<th>规则</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>测试环境请求地址</td>
<td>https://test.api.gdcgps.cn/</td>
</tr>
<tr>
<td>生产环境请求地址</td>
<td>https://api.gdcgps.cn/</td>
</tr>
<tr>
<td>传输协议</td>
<td>https</td>
</tr>
<tr>
<td>参数格式</td>
<td>application/x-www-form-urlencoded</td>
</tr>
<tr>
<td>字符编码</td>
<td>utf-8</td>
</tr>
<tr>
<td>请求方式</td>
<td>POST,GET</td>
</tr>
</tbody>
</table>
<h3 id="san-fan-hui-zhi-gui-ze" tabindex="-1"><a class="header-anchor" href="#san-fan-hui-zhi-gui-ze">¶</a> 三：返回值规则</h3>
<table>
<thead>
<tr>
<th>规则</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>数据格式</td>
<td>application/json</td>
</tr>
<tr>
<td>字符编码</td>
<td>utf-8</td>
</tr>
<tr>
<td>数据结构</td>
<td>{&quot;status&quot;: 状态码,&quot;msg&quot;: 错误信息, &quot;data&quot;: 数据}</td>
</tr>
</tbody>
</table>
<p>状态码为200代表正常，其它代表不正常。</p>
<h3 id="si-jie-kou-ru-can-jie-shao" tabindex="-1"><a class="header-anchor" href="#si-jie-kou-ru-can-jie-shao">¶</a> 四：接口入参介绍</h3>
<p>开放平台接口参数分为系统参数和业务参数两种，其中系统参数每个接口均需传入，业务参数是否传入取决于具体接口。
1：系统参数：clientId，accessToken，timestamp，sign
2：业务参数：data
请求开放平台接口时，系统参数、业务参数均在body请求体中传入</p>
<p>开放平台接口参数列表：</p>
<table>
<thead>
<tr>
<th>参数名称</th>
<th>参数类型</th>
<th>是否必传</th>
<th>参数描述</th>
</tr>
</thead>
<tbody>
<tr>
<td>clientId</td>
<td>String</td>
<td>yes</td>
<td>App Key（去账户中心-&gt;应用信息查看）</td>
</tr>
<tr>
<td>accessToken</td>
<td>String</td>
<td>yes</td>
<td><a href="/docs/merchant_authorization">授权成功获取</a></td>
</tr>
<tr>
<td>timestamp</td>
<td>String</td>
<td>yes</td>
<td>毫秒级时间戳</td>
</tr>
<tr>
<td>data</td>
<td>String(必须是json串)</td>
<td>根据具体业务接口传递</td>
<td>业务入参</td>
</tr>
<tr>
<td>sign</td>
<td>String</td>
<td>yes</td>
<td>签名</td>
</tr>
</tbody>
</table>
<h3 id="wu-shang-hu-shou-quan" tabindex="-1"><a class="header-anchor" href="#wu-shang-hu-shou-quan">¶</a> 五：商户授权</h3>
<p>商户授权请参考<a href="/docs/merchant_authorization">商户授权</a></p>
<h3 id="liu-jie-kou-qian-ming" tabindex="-1"><a class="header-anchor" href="#liu-jie-kou-qian-ming">¶</a> 六：接口签名</h3>
<p>签名说明</p>
<pre><code AAA="">1:为了防止API调用过程中被黑客恶意篡改，调用任何一个API都需要携带签名，开放平台服务端会根据请求参数，对签名进行验证，签名不合法的请求将会被拒绝。
2:将所有参数按照字典顺序进行排序，排除值为空以及sign字段。
3:将参数按照（appSecret的值+accessToken+&quot;accessToken的值&quot;+&quot;clientId&quot;+clientId的值+&quot;data&quot;+data的值+&quot;timestamp”+timestamp的值）顺序拼接，参数对应的值不存在，参数和值都不进行拼接，参数使用utf-8编码。
4: 将拼接好的字符串进行MD5加密(32位大写加密），得到的MD5加密值最后转为&quot;大写&quot;赋给sign作为请求的参数。

参考方法:
一：accessToken参数为空
appSecret:bnRtI4HQQB3JsAAcL5kizXBmzQA3iurW
clientId=ss6DzHy9GvHB46Jgo
data={&quot;refreshToken&quot;:&quot;10cab61c-5bcf-43f9-a120-feee9a24683d&quot;}
timestamp=1631862240982

拼接成的加密串为：bnRtI4HQQB3JsAAcL5kizXBmzQA3iurWclientIdss6DzHy9GvHB46Jgodata{&quot;refreshToken&quot;:&quot;10cab61c-5bcf-43f9-a120-feee9a24683d&quot;}timestamp1631862240982
得到的MD5加密值(32位大写加密）为：6966F7A42902CFFB96C49CF496A9CFC6

二：data参数为空
appSecret:bnRtI4HQQB3JsAAcL5kizXBmzQA3iurW
accessToken=70882965-5e51-463f-a746-7bcf1b3f1c34
clientId=ss6DzHy9GvHB46Jgo
timestamp=1631862240982

拼接成的加密串为：bnRtI4HQQB3JsAAcL5kizXBmzQA3iurWaccessToken70882965-5e51-463f-a746-7bcf1b3f1c34clientIdss6DzHy9GvHB46Jgotimestamp1631862240982
得到的MD5加密值(32位大写加密）为：955E0C0DDAF87B9D26E0171BEF65C0D8

三：data参数不为空
appSecret:qBfAr1fAAFRw2Jat4i4yUaqE3Nh3NSw0
accessToken=70882965-5e51-463f-a746-7bcf1b3f1c34
clientId=ss6DzHy9GvHB46Jgo
timestamp=1631862240982
data={&quot;cityId&quot;:1101}

拼接成的加密串为：bnRtI4HQQB3JsAAcL5kizXBmzQA3iurWaccessToken70882965-5e51-463f-a746-7bcf1b3f1c34clientIdss6DzHy9GvHB46Jgodata{&quot;cityId&quot;:1101}timestamp1631862240982
得到的MD5加密值(32位大写加密）为：sign:56F220DAFDACCD1827544D75EEDEC086
</code></pre>
<h3 id="qi-dai-ma-shi-li" tabindex="-1"><a class="header-anchor" href="#qi-dai-ma-shi-li">¶</a> 七：代码示例</h3>
<p>1、引入类</p>
<pre><code AAA="">import org.apache.commons.fileupload.FileItem;
import org.apache.commons.fileupload.disk.DiskFileItem;
import org.apache.commons.io.IOUtils;
import org.apache.http.HttpEntity;
import org.apache.http.HttpResponse;
import org.apache.http.client.methods.HttpPost;
import org.apache.http.entity.ContentType;
import org.apache.http.entity.mime.MultipartEntityBuilder;
import org.apache.http.impl.client.CloseableHttpClient;
import org.apache.http.impl.client.HttpClients;
import org.apache.http.util.EntityUtils;
import org.springframework.web.multipart.MultipartFile;
import org.springframework.web.multipart.commons.CommonsMultipartFile;
import org.apache.http.NameValuePair;
import org.apache.http.client.entity.UrlEncodedFormEntity;
import org.apache.http.client.methods.CloseableHttpResponse;
import org.apache.http.message.BasicNameValuePair;
import java.util.ArrayList;
import java.util.HashMap;
import java.util.List;
import java.util.Map;
import java.io.File;
import java.io.FileInputStream;
import java.io.IOException;
import java.nio.charset.Charset;
import java.nio.file.Files;
import java.math.BigInteger;  
import java.security.MessageDigest;  
import java.security.NoSuchAlgorithmException;
</code></pre>
<p>2、签名sign参数计算函数(转大写MD5)</p>
<pre><code AAA="">//把字符串转换成大写md5
	public static String stringToMD5(String plainText) {  
	byte[] mdBytes = null;  
	try {  
	mdBytes = MessageDigest.getInstance(&quot;MD5&quot;).digest(  
	plainText.getBytes());  
	} catch (NoSuchAlgorithmException e) {  
	throw new RuntimeException(&quot;MD5算法不存在！&quot;);  
	}  
	StringBuilder mdCode = new StringBuilder(new BigInteger(1, mdBytes).toString(16));  
	  
	if(mdCode.length() &lt; 32) {  
	int a = 32 - mdCode.length();  
	for (int i = 0; i &lt; a; i++) {  
	mdCode.insert(0, &quot;0&quot;);  
	}  
	}  
	return mdCode.toString().toUpperCase(); //返回32位大写  
	// return mdCode; // 默认返回32位小写  
	}
    //把字节数组转换成大写md5
    public static String bytesToMD5(byte[] input) {
        String md5str = null;
        try {
            //创建一个提供信息摘要算法的对象，初始化为md5算法对象
            MessageDigest md = MessageDigest.getInstance(&quot;MD5&quot;);
            //计算后获得字节数组
            byte[] buff = md.digest(input);
            //把数组每一字节换成16进制连成md5字符串
            md5str = bytesToHex(buff);
        } catch (Exception e) {
            e.printStackTrace();
        }
        return md5str.toUpperCase();
    }

   //把字节数组转成16进位制数
    public static String bytesToHex(byte[] bytes) {
        StringBuffer md5str = new StringBuffer();
        //把数组每一字节换成16进制连成md5字符串
        int digital;
        for (int i = 0; i &lt; bytes.length; i++) {
            digital = bytes[i];
            if(digital &lt; 0) {
                digital += 256;
            }
            if(digital &lt; 16){
                md5str.append(&quot;0&quot;);
            }
            md5str.append(Integer.toHexString(digital));
        }
        return md5str.toString();
    }
</code></pre>
<p>3、post请求函数</p>
<pre><code AAA="">public static String sendPost(String url, Map&lt;String,Object&gt; params) {
        String response = null;
        try {
            List&lt;NameValuePair&gt; pairs = null;
            if (params != null &amp;&amp; !params.isEmpty()) {
                pairs = new ArrayList&lt;NameValuePair&gt;(params.size());
                for (String key : params.keySet()) {
                    pairs.add(new BasicNameValuePair(key, params.get(key).toString()));
                }
            }
            CloseableHttpClient httpClient = null;
            CloseableHttpResponse httpResponse = null;
            try {
                httpClient = HttpClients.createDefault();
                HttpPost httppost = new HttpPost(url);
                if (pairs != null &amp;&amp; pairs.size() &gt; 0) {
                    httppost.setEntity(new UrlEncodedFormEntity(pairs, &quot;UTF-8&quot;));
                }
                httpResponse = httpClient.execute(httppost);
                response = EntityUtils
                        .toString(httpResponse.getEntity());
                System.out.println(response);
            } finally {
                if (httpClient != null) {
                    httpClient.close();
                }
                if (httpResponse != null) {
                    httpResponse.close();
                }
            }
        } catch (Exception e) {
            e.printStackTrace();
        }
        return response;
    }
</code></pre>
<p>4、accessToken参数为空请求接口的代码示例（<a href="https://api.gdcgps.cn/docs/developers_guide">RefreshToken刷新AccessToken接口为例</a>）</p>
<pre><code AAA="">public static void main(String[] args) {
        String appSecret = &quot;bnRtI4HQQB3JsAAcL5kizXBmzQA3iurW&quot;;
        String clientId = &quot;ss6DzHy9GvHB46Jgo&quot;;
        String data = &quot;{\&quot;refreshToken\&quot;:\&quot;10cab61c-5bcf-43f9-a120-feee9a24683d\&quot;}&quot;;
        Long timestamp = System.currentTimeMillis();
        StringBuffer sb = new StringBuffer(appSecret)
                .append(&quot;clientId&quot;).append(clientId)
                .append(&quot;data&quot;).append(data)
                .append(&quot;timestamp&quot;).append(timestamp);
        //计算签名
        String sign = bytesToMD5(sb.toString().getBytes());
        Map&lt;String,Object&gt; map = new HashMap&lt;String,Object&gt;();
        map.put(&quot;clientId&quot;,clientId);
        map.put(&quot;data&quot;, data);
        map.put(&quot;timestamp&quot;,timestamp);
        map.put(&quot;sign&quot;,sign);
        //请求接口获取的结果
        String res = sendPost(&quot;http://api.gdcgps.cn/open/api/refresh_token&quot;,map);
    }
</code></pre>
<p>5、data参数为空请求接口的代码示例（<a href="https://api.gdcgps.cn/docs/openCitiesLists">查询开通城市接口为例</a>）</p>
<pre><code AAA="">public static void main(String[] args) {
        String appSecret = &quot;bnRtI4HQQB3JsAAcL5kizXBmzQA3iurW&quot;;
        String clientId = &quot;ss6DzHy9GvHB46Jgo&quot;;
        String accessToken = &quot;70882965-5e51-463f-a746-7bcf1b3f1c34&quot;;
        Long timestamp = System.currentTimeMillis();
        StringBuffer sb = new StringBuffer(appSecret)
                .append(&quot;accessToken&quot;).append(accessToken)
                .append(&quot;clientId&quot;).append(clientId)
                .append(&quot;timestamp&quot;).append(timestamp);
        //计算签名
        String sign = bytesToMD5(sb.toString().getBytes());
        Map&lt;String,Object&gt; map = new HashMap&lt;String,Object&gt;();
        map.put(&quot;clientId&quot;,clientId);
        map.put(&quot;accessToken&quot;, accessToken);
        map.put(&quot;timestamp&quot;,timestamp);
        map.put(&quot;sign&quot;,sign);
        //请求接口获取的结果
        String res = sendPost(&quot;http://api.gdcgps.cn/open/api/openCitiesLists&quot;,map);
    }
</code></pre>
<p>6、data参数不为空请求接口的代码示例（<a href="https://api.gdcgps.cn/open/api/paoOrder/orderInfo">查询订单接口为例</a>）</p>
<pre><code AAA="">public static void main(String[] args) {
        String appSecret = &quot;bnRtI4HQQB3JsAAcL5kizXBmzQA3iurW&quot;;
        String accessToken = &quot;70882965-5e51-463f-a746-7bcf1b3f1c34&quot;;
        String clientId = &quot;ss6DzHy9GvHB46Jgo&quot;;
        String data = &quot;{\&quot;cityId\&quot;:1101}&quot;;
        Long timestamp = System.currentTimeMillis();
        StringBuffer sb = new StringBuffer(appSecret)
                .append(&quot;accessToken&quot;).append(accessToken)
                .append(&quot;clientId&quot;).append(clientId)
                .append(&quot;data&quot;).append(data)
                .append(&quot;timestamp&quot;).append(timestamp);
        //计算签名
        String sign = bytesToMD5(sb.toString().getBytes());
        Map&lt;String,Object&gt; map = new HashMap&lt;String,Object&gt;();
        map.put(&quot;accessToken&quot;, accessToken);
        map.put(&quot;clientId&quot;,clientId);
        map.put(&quot;data&quot;,data);
        map.put(&quot;timestamp&quot;,timestamp);
        map.put(&quot;sign&quot;,sign);
        //请求接口获取的结果
        String res = sendPost(&quot;https://api.gdcgps.cn/open/api/paoOrder/orderInfo&quot;,map);
    }
</code></pre>
<p>7、accessToken和data参数都为空请求接口的代码示例（<a href="http://api.gdcgps.cn/open/api/fileUpload">上传图片接口为例</a>）</p>
<pre><code AAA="">public static void main(String[] args) throws IOException {
        String appSecret = &quot;bnRtI4HQQB3JsAAcL5kizXBmzQA3iurW&quot;;
        String clientId = &quot;ss6DzHy9GvHB46Jgo&quot;;
        Long timestamp = System.currentTimeMillis();
        StringBuffer sb = new StringBuffer(appSecret)
                .append(&quot;clientId&quot;).append(clientId)
                .append(&quot;timestamp&quot;).append(timestamp);
        //计算签名
        String sign = bytesToMD5(sb.toString().getBytes());
        //图片文件的路径
        File file = new File(&quot;/Users/Desktop/WX20211108-164848.png&quot;);
        FileItem fileItem = new DiskFileItem(
                &quot;iss&quot;,
                Files.probeContentType(file.toPath()),
                false,
                file.getName(),
                (int) file.length(),
                file.getParentFile());
        IOUtils.copy(new FileInputStream(file), fileItem.getOutputStream());
        MultipartFile cMultiFile = new CommonsMultipartFile(fileItem);
        System.out.println(cMultiFile.getOriginalFilename());
        String fileName = cMultiFile.getOriginalFilename();
        CloseableHttpClient client = HttpClients.createDefault();
        MultipartEntityBuilder builder = MultipartEntityBuilder.create();
        builder.addTextBody(&quot;sign&quot;, sign, ContentType.create(ContentType.MULTIPART_FORM_DATA.getMimeType(), Charset.defaultCharset()));
        builder.addTextBody(&quot;clientId&quot;, clientId, ContentType.create(ContentType.MULTIPART_FORM_DATA.getMimeType(), &quot;utf-8&quot;));
        builder.addTextBody(&quot;timestamp&quot;, &quot;&quot; + timestamp, ContentType.create(ContentType.MULTIPART_FORM_DATA.getMimeType(), &quot;utf-8&quot;));
        builder.addBinaryBody(&quot;file&quot;, cMultiFile.getInputStream(), ContentType.MULTIPART_FORM_DATA, fileName);
        HttpEntity build = builder.build();
        HttpPost httpPost = new HttpPost(&quot;http://api.gdcgps.cn/open/api/fileUpload&quot;);
        httpPost.setEntity(build);
        HttpResponse httpResponse = null;
        try {
            httpResponse = client.execute(httpPost);
            String response = EntityUtils
                    .toString(httpResponse.getEntity());
            System.out.println(response);
        } catch (IOException e) {
            e.printStackTrace();
        }
    }
</code></pre>

      </section>
    </template>
    <script>
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div')}
var staticRenderFns = []

  
    const democomponentExport = {}
    return {
      render,
      staticRenderFns,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
   <style></style>
  