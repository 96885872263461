<template>
  <div>
    <el-upload  :lock-scroll="true" :multiple="false" :show-file-list="false" :on-remove="handleRemove" :on-success="handleSuccess" :before-upload="beforeUpload" class="editor-slide-upload" :http-request="uploadSectionFile" action="">
     <div class="upload-card" :style="{width:width+'px',height:height+'px',lineHeight:height+'px'}">
        <i slot="default" class="el-icon-plus" style="font-size: 25px;"></i>
     </div>

      <div slot="file" slot-scope="{file}">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
            <i class="el-icon-zoom-in"></i>
          </span>
          <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </DIV>
</template>

<script>
import { compressImage } from '@/utils/compress'
import Config from '@/config/config';
export default {
  name: "ImageUpload",
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList:[]
    };
  },
  model: {
    prop: 'value', // 小程序能只使用value才有效
    event: 'input' // 小程序能只使用input才有效
  },
  props: {
    value: {
      type: String,
      default: null
    },
    width: {
      type: Number,
      default: 138
    },
    height: {
      type: Number,
      default: 88
    },
    accept: {
      type: String,
      default: "image"
    }
  },
  watch: {
    value: function (newVal, oldVal) {
    },
    immediate: true,
  },

  mounted() {
    console.log("xjSingleUpload mounted:", Config.baseUrl);
    this.filePathsList = []
    if (this.value != null && this.value != '') {
      this.filePathsList.push({
        url: Config.baseUrl + this.value,
        baseUrl: this.value,
      })
    }
  },
  methods: {
    // 上传
    beforeUpload(file) {
      console.log('beforeUpload')
      const _self = this
      const _URL = window.URL || window.webkitURL
      const fileName = file.uid
      this.fileList[fileName] = {}
      return new Promise((resolve) => {
        compressImage(file).then((result) => {
          const img = new Image()
          file = result.compressFile
          img.src = _URL.createObjectURL(file)
          img.onload = function () {
            _self.fileList[fileName] = {
              hasSuccess: false,
              uid: file.uid,
              width: this.width,
              height: this.height
            }
          }
          resolve(true)
        })
      })
    },    
    uploadSectionFile(params) { // 传图
      console.log('uploadSectionFile:',params)
      // 自定义文件上传
      var file = params.file
      var fileType = file.type
      var isImage = fileType.indexOf('image') != -1
      // const isLt2M = file.size / 1024 / 1024 < 8
      if (!isImage) {
        this.$message.error('请选择图片类型');
        return
      } else {
        compressImage(file).then((result) => {
          // result.compressFile 压缩后新的file对象 // 小于10M不压缩直接返回
          const formData = new FormData()
          formData.append('file', result.compressFile)
          this.$http.post('/common/upload', formData, {
              headers: { 'content-type': 'multipart/form-data' }
            }).then(res => {
              console.log('uploadSectionFile:', res)
            this.$emit('input', res.data.url)
            })
        })
      }
    },

    // 上传成功后执行 没用的了
    handleSuccess(response, file) {
      console.log('handleSuccess')
      const uid = file.uid
      const objKeyArr = Object.keys(this.fileList)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.fileList[objKeyArr[i]].uid === uid) {
          // this.fileList[objKeyArr[i]].url = response.files.file
          this.fileList[objKeyArr[i]].url = response
          this.fileList[objKeyArr[i]].hasSuccess = true
          return
        }
      }
      this.getImgList()
    },
    handleRemove(file) {
      console.log('handleRemove')
      const uid = file.uid
      const objKeyArr = Object.keys(this.fileList)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.fileList[objKeyArr[i]].uid === uid) {
          delete this.fileList[objKeyArr[i]]
          return
        }
      }
    },
  }

}
</script>

<style >
.upload-card {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;
  vertical-align: top;
}
</style>