var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content element-doc"},[_vm._m(0),_vm._m(1),_c('demo-block',[_c('template',{slot:"source"},[_c('element-demo0',{staticClass:"element-demo0"})],1)],2),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{attrs:{"id":"ding-dan-yu-qu-xiao","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ding-dan-yu-qu-xiao"}},[_vm._v("¶")]),_vm._v(" 订单预取消")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("接口说明：\n1、用来进行计费使用，可以理解为下单询价。需要注意的是计费和真正支付的金额可能存在不一样。\n2、测试环境的计费规则是随意配置的，一切订单计价的费用以线上为准，理论上测试环境只要能够计费成功就可以。\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"qing-qiu-url","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#qing-qiu-url"}},[_vm._v("¶")]),_vm._v(" 请求url")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('code',[_vm._v("POST")]),_vm._v(" /open/api/paoOrder/preAbortOrder")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"qian-ming-ji-suan","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#qian-ming-ji-suan"}},[_vm._v("¶")]),_vm._v(" 签名计算")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"/docs/developers_guide"}},[_vm._v("请参考开发指南")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ce-shi-lian-diao","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ce-shi-lian-diao"}},[_vm._v("¶")]),_vm._v(" 测试联调")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"/docs/testing_and_debugging"}},[_vm._v("联调说明")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ru-can-shuo-ming","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ru-can-shuo-ming"}},[_vm._v("¶")]),_vm._v(" 入参说明")])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("参数")]),_c('th',[_vm._v("示例")]),_c('th',[_vm._v("说明")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("clientId")]),_c('td',[_vm._v("ss6DzHy9GvHB46Jgo")]),_c('td',[_vm._v("App-key")])]),_c('tr',[_c('td',[_vm._v("accessToken")]),_c('td',[_vm._v("70882965-5e51-463f-a746-7bcf1b3f1c34")]),_c('td',[_c('a',{attrs:{"href":"/docs/merchant_authorization"}},[_vm._v("通过商户授权获取")])])]),_c('tr',[_c('td',[_vm._v("timestamp")]),_c('td',[_vm._v("1631862240982")]),_c('td',[_vm._v("毫秒级时间戳")])]),_c('tr',[_c('td',[_vm._v("data")]),_c('td',[_vm._v("参考入参示例")]),_c('td',[_vm._v("业务入参")])]),_c('tr',[_c('td',[_vm._v("sign")]),_c('td',[_vm._v("63068EBC3C28B7F822F74DAB874D4027")]),_c('td',[_vm._v("签名计算参考"),_c('a',{attrs:{"href":"/docs/developers_guide"}},[_vm._v("开发指南")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"datacan-shu-shuo-ming","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#datacan-shu-shuo-ming"}},[_vm._v("¶")]),_vm._v(" data参数说明")])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("参数")]),_c('th',[_vm._v("字段描述")]),_c('th',[_vm._v("类型/是否必传")]),_c('th',[_vm._v("说明")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("orderId")]),_c('td',[_vm._v("订单ID号")]),_c('td',[_vm._v("String(32)/YES")]),_c('td',[_c('a',{attrs:{"href":"/docs/openCitiesLists"}},[_vm._v("提交订单接口获取")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"chu-can-shuo-ming","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#chu-can-shuo-ming"}},[_vm._v("¶")]),_vm._v(" 出参说明")])
},function (){var _vm=this,_c=_vm._self._c;return _c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("参数")]),_c('th',[_vm._v("类型/是否必传")]),_c('th',[_vm._v("说明")]),_c('th',[_vm._v("示例")])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v("sendBackFee")]),_c('td',[_vm._v("Integer(8)/YES")]),_c('td',[_vm._v("送回费，单位：分（取件后取消订单，当取消费大于订单实付金额时，会产生送回费，需用户额外支付）")]),_c('td',[_vm._v("0")])]),_c('tr',[_c('td',[_vm._v("punishAmount")]),_c('td',[_vm._v("Integer(8)/YES")]),_c('td',[_vm._v("扣款金额，单位：分")]),_c('td',[_vm._v("0")])]),_c('tr',[_c('td',[_vm._v("desc")]),_c('td',[_vm._v("String(100)/YES")]),_c('td',[_vm._v("扣款金额的解释说明")]),_c('td',[_vm._v("频繁取消订单，可能会产生取消费。若因闪送员原因取消，请联系闪送员操作取消或投诉闪送员")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ru-can-shi-li-datawei-ye-wu-can-shu-jsonxu-lie-hua-hou-chuan-ru","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ru-can-shi-li-datawei-ye-wu-can-shu-jsonxu-lie-hua-hou-chuan-ru"}},[_vm._v("¶")]),_vm._v(" 入参示例(data为业务参数，Json序列化后传入 )")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("{\n    \"clientId\": \"t8ljLE2mKPwEgAcVr\",\n    \"accessToken\": \"d077bb79-4c8b-46a8-a06e-4a01a98bff6a\",\n    \"timestamp\": \"1631862240982\",\n    \"sign\": \"A1B4871AF792FDF449FE5291D4206D13\",\n    \"data\": \"{\\\"orderId\\\": \\\"1751934464728399874\\\"}\"\n}\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"ye-wu-can-shu-shi-li","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#ye-wu-can-shu-shi-li"}},[_vm._v("¶")]),_vm._v(" 业务参数示例")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("{\n    \"orderId\": \"1751934464728399874\"\n}\n\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"chu-can-shi-li","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#chu-can-shi-li"}},[_vm._v("¶")]),_vm._v(" 出参示例")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("{\n    \"msg\": \"操作成功\",\n    \"code\": 200,\n    \"data\": {\n        \"punishAmount\": 0,\n        \"sendBackFee\": 0,\n        \"desc\": \"频繁取消订单，可能会产生取消费。若因配送员原因取消，请联系配送员操作取消或投诉配送员\"\n    },\n    \"status\": \"SUCCESS\"\n}\n")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{attrs:{"id":"jie-kou-zhu-yi-dian","tabindex":"-1"}},[_c('a',{staticClass:"header-anchor",attrs:{"href":"#jie-kou-zhu-yi-dian"}},[_vm._v("¶")]),_vm._v(" 接口注意点")])
},function (){var _vm=this,_c=_vm._self._c;return _c('pre',[_c('code',{attrs:{"AAA":""}},[_vm._v("\n\n")])])
}]

export { render, staticRenderFns }