import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import Config from '@/config/config';
import errorCode from '@/utils/errorCode'

// create an axios instance
// console.log('------------------process.env.VUE_APP_BASE_URL:', process.env)
console.log('------------------Config.baseUrl:', Config.baseUrl)
const service = axios.create({
  baseURL: Config.baseUrl, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
})


// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    console.log('-------accessToken：', store.getters.accessToken)
    if (store.getters.accessToken) {
      config.headers['third-session'] = store.getters.accessToken
    }
    console.log('%c请求前地址信息：', 'color:green', config.method + ':' + config.baseURL + config.url)
    console.log('%c请 求 前 数 据：', 'color:green', config.data)
    return config
  },
  error => {
    // do something with request error
    console.log('request.js request error', error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(res => {
  console.log('response:' + res)
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200;
  // 获取错误信息
  const msg = errorCode[code] || res.data.msg || errorCode['default']
  if (code === 60002 || code ===60001) {
    MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }
    ).then(() => {
      // 重新登录
      console.log("登录状态已过期:logout")
      store.commit('logout')
      // this.$router.push({ path: '/login' })
      location.href = '/login'; 
      // store.commit('logout').then(() => {
      //   location.href = '/login'; 
      //   // this.$router.push({ path: '/login' })
      // })
    })
  } else if (code === 500) {
    Message({
      message: msg,
      type: 'error'
    })
    return Promise.reject(new Error(msg))
  } else if (code !== 200) {
    Message({
      message: msg,
      type: 'error'
    })
    return Promise.reject('error')
  } else {
    return res.data
  }
},
error => {
  console.log('err' + error)
  let { message } = error;
  if (message == "Network Error") {
    message = "后端接口连接异常";
  }
  else if (message.includes("timeout")) {
    message = "系统接口请求超时";
  }
  else if (message.includes("Request failed with status code")) {
    message = "系统接口" + message.substr(message.length - 3) + "异常";
  }
  Message({
    message: message,
    type: 'error',
    duration: 5 * 1000
  })
  return Promise.reject(error)
}
)
export default service
