
    <template>
      <section class="content element-doc">
        <h3 id="shang-hu-ding-dan-lie-biao-cha-xun" tabindex="-1"><a class="header-anchor" href="#shang-hu-ding-dan-lie-biao-cha-xun">¶</a> 商户订单列表查询</h3>
<pre><code AAA="">accessToken接口说明：
1：用来获取订单列表，根据商户授权的accessToken查对应的商户订单列表。
</code></pre>
<demo-block>
        
        <template slot="source"><element-demo0 class="element-demo0"/></template>
        </demo-block><h4 id="qing-qiu-url" tabindex="-1"><a class="header-anchor" href="#qing-qiu-url">¶</a> 请求url</h4>
<p><code>POST</code>  /open/api/paoOrder/orderPage</p>
<h4 id="qian-ming-ji-suan" tabindex="-1"><a class="header-anchor" href="#qian-ming-ji-suan">¶</a> 签名计算</h4>
<p><a href="/docs/developers_guide">请参考开发指南</a></p>
<h4 id="ce-shi-lian-diao" tabindex="-1"><a class="header-anchor" href="#ce-shi-lian-diao">¶</a> 测试联调</h4>
<p><a href="/docs/testing_and_debugging">联调说明</a></p>
<h4 id="ru-can-shuo-ming" tabindex="-1"><a class="header-anchor" href="#ru-can-shuo-ming">¶</a> 入参说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>示例</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>clientId</td>
<td>ss6DzHy9GvHB46Jgo</td>
<td>App-key</td>
</tr>
<tr>
<td>accessToken</td>
<td>70882965-5e51-463f-a746-7bcf1b3f1c34</td>
<td><a href="/docs/merchant_authorization">通过商户授权获取</a></td>
</tr>
<tr>
<td>timestamp</td>
<td>1631862240982</td>
<td>毫秒级时间戳</td>
</tr>
<tr>
<td>data</td>
<td>参考入参示例</td>
<td>业务入参</td>
</tr>
<tr>
<td>sign</td>
<td>63068EBC3C28B7F822F74DAB874D4027</td>
<td>签名计算参考<a href="/docs/developers_guide">开发指南</a></td>
</tr>
</tbody>
</table>
<h4 id="datacan-shu-shuo-ming" tabindex="-1"><a class="header-anchor" href="#datacan-shu-shuo-ming">¶</a> data参数说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>字段描述</th>
<th>类型/是否必传</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>current</td>
<td>页面</td>
<td>Integer(8)/NO</td>
<td>查询第几页，默认:1</td>
</tr>
<tr>
<td>size</td>
<td>每页数据量</td>
<td>Integer(8)/NO</td>
<td>默认:10</td>
</tr>
<tr>
<td>ascs</td>
<td>每页数据量</td>
<td>String(8)/NO</td>
<td>升序字段名 ascs与descs只需要写1个，</td>
</tr>
<tr>
<td>descs</td>
<td>每页数据量</td>
<td>String(8)/NO</td>
<td>降序字段名 ascs与descs只需要写1个</td>
</tr>
<tr>
<td>orderStatus</td>
<td>状态</td>
<td>String(8)/NO</td>
<td>默认：0  0：全部 1：进行中 2：完成</td>
</tr>
</tbody>
</table>
<h4 id="chu-can-shuo-ming" tabindex="-1"><a class="header-anchor" href="#chu-can-shuo-ming">¶</a> 出参说明</h4>
<table>
<thead>
<tr>
<th>参数</th>
<th>类型/是否必传</th>
<th>说明</th>
<th>示例</th>
</tr>
</thead>
<tbody>
<tr>
<td>records</td>
<td>Objext[]/YES</td>
<td>订单列表</td>
<td></td>
</tr>
<tr>
<td>total</td>
<td>Integer(2)/YES</td>
<td>总数</td>
<td></td>
</tr>
<tr>
<td>current</td>
<td>页面</td>
<td>Integer(8)/NO</td>
<td>查询第几页，默认:1</td>
</tr>
<tr>
<td>size</td>
<td>每页数据量</td>
<td>Integer(8)/NO</td>
<td>默认:10</td>
</tr>
<tr>
<td>orders</td>
<td>Integer(2)/YES</td>
<td>排序方式</td>
<td></td>
</tr>
<tr>
<td>pages</td>
<td>Integer(2)/YES</td>
<td>总页数</td>
<td></td>
</tr>
</tbody>
</table>
<h4 id="ru-can-shi-li-datawei-ye-wu-can-shu-jsonxu-lie-hua-hou-chuan-ru" tabindex="-1"><a class="header-anchor" href="#ru-can-shi-li-datawei-ye-wu-can-shu-jsonxu-lie-hua-hou-chuan-ru">¶</a> 入参示例(data为业务参数，Json序列化后传入 )</h4>
<pre><code AAA="">{
    &quot;clientId&quot;: &quot;t8ljLE2mKPwEgAcVr&quot;,
    &quot;accessToken&quot;: &quot;d077bb79-4c8b-46a8-a06e-4a01a98bff6a&quot;,
    &quot;timestamp&quot;: &quot;1631862240982&quot;,
    &quot;sign&quot;: &quot;FB993D2759E82E7050111BF30E988667&quot;,
    &quot;data&quot;: &quot;{\&quot;current\&quot;: 2}&quot;
}
</code></pre>
<h4 id="ye-wu-can-shu-shi-li" tabindex="-1"><a class="header-anchor" href="#ye-wu-can-shu-shi-li">¶</a> 业务参数示例</h4>
<pre><code AAA="">{
      current: 1,
      size: 10,
      descs: 'create_time'
}

</code></pre>
<h4 id="chu-can-shi-li" tabindex="-1"><a class="header-anchor" href="#chu-can-shi-li">¶</a> 出参示例</h4>
<pre><code AAA="">{
    &quot;msg&quot;: &quot;操作成功&quot;,
    &quot;code&quot;: 200,
    &quot;data&quot;: {
        &quot;records&quot;: [],
        &quot;total&quot;: 58,
        &quot;size&quot;: 10,
        &quot;current&quot;: 2,
        &quot;orders&quot;: [],
        &quot;optimizeCountSql&quot;: true,
        &quot;hitCount&quot;: false,
        &quot;searchCount&quot;: true,
        &quot;pages&quot;: 6
    },
    &quot;status&quot;: &quot;SUCCESS&quot;
}
</code></pre>
<h4 id="jie-kou-zhu-yi-dian" tabindex="-1"><a class="header-anchor" href="#jie-kou-zhu-yi-dian">¶</a> 接口注意点</h4>
<pre><code AAA="">

</code></pre>

      </section>
    </template>
    <script>
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div')}
var staticRenderFns = []

  
    const democomponentExport = {}
    return {
      render,
      staticRenderFns,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
   <style></style>
  